<template>
  <div class="ad-sheet-container">
    <div class="modal fade" id="preview-modal" tabindex="-1" role="dialog" aria-labelledby="adModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document" style="width:80%">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="adModalLabel">
              <Row>
                <Row-Col span="3">
                  Preview
                </Row-Col>
                <Row-Col span="5" offset="12" v-if="!isPromotionMode">
                  <Select v-model="creativeInPreview"
                   :transfer="true"
                    filterable>
                    <Option v-for="(creative, index) in previewableRowsCreativeSpec" :key="index+1" :value="index+1">{{ creative.name }}</Option>
                  </Select>
                </Row-Col>
                 <Row-Col span="4" v-if="!isPromotionMode">
                  <Page class-name="cl-adpreview-page" :current="creativeInPreview" :total="previewableRowsCreativeSpec.length" :page-size=1 simple size="small" v-on:on-change="updateCreativeInPreview"/>
                </Row-Col>
              </Row>
            </h4>
          </div>
          <div class="modal-body">
            <facebook-creative-preview
              v-if="selectedPublisher == publisher.Facebook"
              fromCampaignLauncher='true'
              :accountId="accountId"
              :creativeSpec="creativeSpecWrapper"
              :businessProfilesSearchEngineId="businessProfilesSearchEngineId"
              :languageCustomizationEnabled="isCreativeInPreviewLanguageCustomized">
            </facebook-creative-preview>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div>

    <div v-if="checkInfoViewToBeRendered">
      <div v-if="selectedPublisher == publisher.Adwords && !deltax.isAdwordsCampaignLaucherFlow" class="alert alert-info">
        We currently only support creation of Google Ads campaigns from Campaign Launcher.
      </div>
      <div v-else-if="selectedPublisher == publisher.Adwords && !selectedCampaignLauncherConfigId" class="alert alert-info">
        Select a campaign configuration to start creating ads.
      </div>
      <div v-else-if="selectedPublisher == publisher.Adwords && isOauthExpired" class="alert alert-danger">
        OAuth for Google account "{{ getSelectedAccountInfo }}" has expired. Please re-authorize <a :href="deltax.channelsPageRedirectUrl"><strong>here</strong></a>.
      </div>
      <div v-else-if="isObjectiveNotPresent && selectedPublisher == publisher.AMS.SponsoredProducts|| selectedPublisher == publisher.AMS.SponsoredBrands|| selectedPublisher == publisher.AMS.SponsoredDisplay" class="alert alert-info">
        Targets will be optimised based on cluster groups and past performance
      </div>
      <div v-else-if="selectedPublisher == publisher.Moloco && !selectedCampaignLauncherConfigId" class="alert alert-info">
        Select a campaign configuration to start creating ads.
      </div>
      <!-- alert-info when no FbObjective is selected -->
      <div v-else-if="isObjectiveNotPresent" class="alert alert-info">
        Select a campaign configuration to start creating ads.
      </div>
      <div v-else-if="isOauthExpired" class="alert alert-danger">
        OAuth for Facebook account "{{ getSelectedAccountInfo }}" has expired. Please re-authorize <a :href="deltax.channelsPageRedirectUrl"><strong>here</strong></a>.
      </div>
      <!-- show spinner when imageIdUrlMap isn't built yet  -->
      <div v-else class="hot-spinner">
        <i class="fa fa-lg fa-spinner fa-spin" />
      </div>
    </div>
    <template v-else-if="selectedPublisher == publisher.Facebook">
      <div class="save-time">
        <span style="color:#999">Last saved at: </span> {{ publishedCreativesByCampaignConfigId[selectedCampaignLauncherConfigId] ? publishedCreativesByCampaignConfigId[selectedCampaignLauncherConfigId].CreativeUpdatedAt : creativeSavedAt ? creativeSavedAt : 'No saved data' }}
      </div>
      <div class="edit-toggle" v-show="!isPromotionMode">
        <RadioGroup v-model="adSheetMode" @on-change="adSheetModeChange" type="button" button-style="solid" size="small">
            <Radio id="create-mode" class="create-mode" label="Create"></Radio>
            <Radio id="edit-mode" label="Edit"></Radio>
            <Radio id="publish-mode" label="Published"></Radio>
        </RadioGroup>
      </div>
      <div v-show="(!isEditMode && !isPublishMode) || isPromotionMode">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="isPromotionMode ? capitalize(adFormat) : adsCountCreateTab(adFormat)"
            :name="capitalize(adFormat)"

          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers"  v-tooltip="'Click to copy headers'">
              <!-- <v-tooltip trigger="hover" :content="'Click to copy headers'"> -->
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
              <!-- </v-tooltip> -->
            </div>
            <div class="ad-sheet-settings">
              <facebook-adsheet-settings
              refs="adsheetSettings"
              v-if="(showPlacementCustomizationSettings(adFormat) || checkIfLanguageCustomizationSupported(adFormat)) && (isCreateSheetDataCallCompletedForFacebook || isPromotionMode)"
              :placementOptions="placementOptions[adFormat]"
              :placementCustomizationHeaders="placementCustomizationConfigHeaders[adFormat]"
              :languageCustomizationHeaders="languageCustomizationConfigHeaders[adFormat]"
              :DynamicCreativeHeaders="dynamicCreativeConfigHeaders[adFormat]"
              :publishedCreatives="publishedCreatives"
              @savedAdSheetSettings="saveAdSheetSettings($event, adFormat)"
              :isDpaObjective="isDPAObjective(selectedCampaignLauncherConfig)"
              :tab="adFormat"
              :adLabelDimensionSettings="adLabelDimensionSettings"
              :showPlacementCustomization="showPlacementCustomizationSettings(adFormat)"
              :isDynamicCreative="selectedCampaignLauncherConfig.adset.isDynamicCreative"
              />
            </div>
            <FacebookHotWrapper :ref="`${adFormat}HotTable`"
              @onPreview="showPreview"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :bpTimeZone="businessProfileTimezone"
              :colHeaders="colHeaders[adFormat]"
              :displayColHeaders="displayColHeaders[adFormat]"
              :requiredCols="requiredCols[adFormat]"
              :creativeSpec="creativeSpec[adFormat]"
              :callToAction="callToAction[adFormat]"
              :promoModeAutoFill="promoModeAutoFill[adFormat]"
              :placementOptions="placementOptions[adFormat]"
              :placementCustomizationHeaders="placementCustomizationHeaders[adFormat]"
              :languageCustomizationHeaders="languageCustomizationHeaders[adFormat]"
              :showCreativeSetup="showCreativeSetup[adFormat]"
              :key="placementCustomizationHeaders[adFormat] || languageCustomizationHeaders[adFormat] ? rerendererKey : 1"
              >
            </FacebookHotWrapper>
          </TabPane>
        </Tabs>
      </div>
      <div v-if="!isPromotionMode && ((isEditMode && !isPublishMode) || isEditDataFetchComplete)" v-show="isEditMode && !isPublishMode">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="adsCountEditTab(adFormat)"
            :name="capitalize(adFormat)"
          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers">
              <v-tooltip trigger="hover" :content="'Click to copy headers'">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
              </v-tooltip>
            </div>
            <div class="ad-sheet-settings">
              <facebook-adsheet-settings
              refs="adsheetSettings"
              :placementOptions="placementOptions[adFormat]"
              :placementCustomizationHeaders="placementCustomizationConfigHeaders[adFormat]"
              :publishedCreatives="publishedCreatives"
              @savedAdSheetSettings="saveAdSheetSettings($event, adFormat)"
              :isDpaObjective="isDPAObjective(selectedCampaignLauncherConfig)"
              :tab="adFormat"
              :adLabelDimensionSettings="adLabelDimensionSettings"
              :showPlacementCustomization="showPlacementCustomizationSettings(adFormat)"
              />
            </div>
            <FacebookEditHotWrapper :ref="`${adFormat}HotTableEdit`" v-if="isEditDataFetchComplete"
              @onPreview="showPreview"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :bpTimeZone="businessProfileTimezone"
              :editColHeaders='["HOT_Preview Ad", "HOT_Campaign Name", "HOT_Adgroup Name", "HOT_Ad Id", "HOT_Ad Status"].concat(colHeaders[adFormat].slice(1))'
              :editDisplayColHeaders='["Preview Ad", "Campaign Name", "Adgroup Name", "Ad Id", "Ad Status"].concat(displayColHeaders[adFormat].slice(1))'
              :requiredCols="{...requiredCols[adFormat]}"
              :creativeSpec="creativeSpec[adFormat]"
              :callToAction="callToAction[adFormat]"
              :promoModeAutoFill="promoModeAutoFill[adFormat]"
              :placementOptions="placementOptions[adFormat]"
              :showCreativeSetup="showCreativeSetup[adFormat]"
              :placementCustomizationHeaders="placementCustomizationHeaders[adFormat]"
              :isEditDataFetchComplete="isEditDataFetchComplete"
              :extraPlacementCustomizationHeaders="extraPlacementCustomizationHeaders"
              :key="placementCustomizationHeaders[adFormat] ? rerendererKey : 1"
              >
            </FacebookEditHotWrapper>
            <div v-if="!isEditDataFetchComplete" class="hot-spinner">
              <i class="fa fa-lg fa-spinner fa-spin" />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div v-if="!isPromotionMode && ((isPublishMode && !isEditMode))">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="adsCountPublishTab(adFormat)"
            :name="capitalize(adFormat)"
          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers">
              <Tooltip theme="light" :transfer="true">
              <Button size="small" @click="copyHeaders(adFormat)">
                <i class="fa fa-copy" />
              </Button>
              <span slot="content">Click to copy headers</span>
              </Tooltip>
            </div>
            <div v-if="!isEditDataFetchComplete" class="hot-spinner">
              <i class="fa fa-lg fa-spinner fa-spin" />
            </div>
            <div class="ad-sheet-settings">
              <facebook-adsheet-settings
              refs="adsheetSettings"
              :placementOptions="placementOptions[adFormat]"
              :placementCustomizationHeaders="placementCustomizationConfigHeaders[adFormat]"
              :publishedCreatives="publishedCreatives"
              @savedAdSheetSettings="saveAdSheetSettings($event, adFormat)"
              :isDpaObjective="isDPAObjective(selectedCampaignLauncherConfig)"
              :tab="adFormat"
              :adLabelDimensionSettings="adLabelDimensionSettings"
              :showPlacementCustomization="showPlacementCustomizationSettings(adFormat)"
              />
            </div>
            <FacebookPublishHotWrapper :ref="`${adFormat}HotTablePublish`"
              @onPreview="showPreview"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :bpTimeZone="businessProfileTimezone"
              :colHeaders="addColumns(colHeaders[adFormat])"
              :displayColHeaders="addColumns(displayColHeaders[adFormat])"
              :requiredCols="requiredCols[adFormat]"
              :creativeSpec="creativeSpec[adFormat]"
              :callToAction="callToAction[adFormat]"
              :promoModeAutoFill="promoModeAutoFill[adFormat]"
              :placementOptions="placementOptions[adFormat]"
              :placementCustomizationHeaders="placementCustomizationHeaders[adFormat]"
              :showCreativeSetup="showCreativeSetup[adFormat]"
              :key="placementCustomizationHeaders[adFormat] ? rerendererKey : 1"
              :isPublishedDataFetchComplete="isEditDataFetchComplete"
              >
            </FacebookPublishHotWrapper>
          </TabPane>
        </Tabs>
      </div>
    </template>
    <template v-else-if="selectedPublisher == publisher.LinkedIn">
      <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
        <TabPane class="tab-pane capitalize"
          v-for="adFormat in adFormats"
          :key="adFormat"
          :label="capitalize(adFormat)"
          :name="capitalize(adFormat)"
          :disabled="adFormatNeedsToBeDisabled(adFormat)"
        >
          <transition name="adsheet-alert">
            <div
              v-show="alert.show && alert.tab == adFormat"
              class="alert alert-warning"
              role="alert"
            >
              <button type="button" class="close" @click="alert.show = false">
                ×
              </button>
              <div v-html="alert.message"></div>
            </div>
          </transition>
          <LinkedInHotWrapper :ref="`${adFormat}HotTable`"
            @onSetAdSheetAlert="setAdSheetAlert"
            :tab="adFormat"
            :colHeaders="colHeaders[adFormat]"
            :creativeSpec="creativeSpec[adFormat]"
            :displayColHeaders="displayColHeaders[adFormat]"
            :requiredCols="requiredCols[adFormat]"
            :callToAction="callToAction[adFormat]"
          />
        </TabPane>
      </Tabs>
    </template>
    <template v-else-if="selectedPublisher == publisher.Twitter">
      <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll linkedIn-adsheet-tab">
        <TabPane class="tab-pane capitalize"
          v-for="adFormat in adFormats"
          :key="adFormat"
          :label="capitalize(adFormat)"
          :name="capitalize(adFormat)"
          :disabled="adFormatNeedsToBeDisabled(adFormat)"
        >
          <transition name="adsheet-alert">
            <div
              v-show="alert.show && alert.tab == adFormat"
              class="alert alert-warning"
              role="alert"
            >
              <button type="button" class="close" @click="alert.show = false">
                ×
              </button>
              <div v-html="alert.message"></div>
            </div>
          </transition>
          <TwitterHotWrapper :ref="`${adFormat}HotTable`"
            @onSetAdSheetAlert="setAdSheetAlert"
            :tab="adFormat"
            :colHeaders="colHeaders[adFormat]"
            :creativeSpec="creativeSpec[adFormat]"
            :displayColHeaders="displayColHeaders[adFormat]"
            :requiredCols="requiredCols[adFormat]"
            :callToAction="callToAction[adFormat]"
          />
        </TabPane>
      </Tabs>
    </template>
    <template v-else-if="selectedPublisher == publisher.DV360">
      <div v-if="adFormats.length == 0" class="alert alert-info">
        Please map targets to the campaign configuration to start creating ads.
      </div>
      <Tabs v-else v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll linkedIn-adsheet-tab">
        <TabPane class="tab-pane capitalize"
          v-for="adFormat in adFormats"
          :key="adFormat"
          :label="capitalize(adFormat)"
          :name="capitalize(adFormat)"
        >
          <DV360HotWrapper
            :ref="`${adFormat}HotTable`"
            @onSetAdSheetAlert="setAdSheetAlert"
            :tab="adFormat"
            :colHeaders="colHeaders[adFormat]"
            :creativeSpec="creativeSpec[adFormat]"
            :displayColHeaders="displayColHeaders[adFormat]"
            :requiredCols="requiredCols[adFormat]"
            :callToAction="callToAction[adFormat]"
          />
        </TabPane>
      </Tabs>
    </template>
    <template v-else-if="selectedPublisher == publisher.AMS.SponsoredProducts">
          <Tabs :animated="false" type="card" class="tabs dx-tab-style hot-scroll" name="amsSpTab" v-on:on-click="tabSwitch">
            <div v-if ="JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config).campaign.targetingType == 1">
          <TabPane
            v-for="adFormat in spFormats"
            :key="adFormat"
            :label="adFormat"
            :name="adFormat"
            tab="amsSpTab"
          >
          <AmazonSpHotWrapper
          :ref="`amazonSpHotTable`"
          :sptab ="adFormat"
          :currentAmsTab = currentAmsTab
          />
          </TabPane> </div> <div v-else>
            <TabPane
            v-for="spAutoFormat in spAutoFormats"
            :key="spAutoFormat"
            :label="spAutoFormat"
            :name="spAutoFormat"
            tab="amsSpTab"
          >
          <AmazonSpHotWrapper
          :ref="`amazonSpHotTable`"
          :sptab ="spAutoFormat"
          />
          </TabPane> </div>
      </Tabs>
    </template>
    <template v-else-if="selectedPublisher == publisher.Adwords">
      <div class="edit-toggle" v-show="!isPromotionMode">
        <RadioGroup v-model="adSheetMode" @on-change="adSheetModeChange" type="button" button-style="solid" size="small" v-if="isAdwordsPublishTabSupported">
            <Radio id="create-mode" class="create-mode" label="Create"></Radio>
            <Radio v-if="EditTabEnabled" id="edit-mode" label="Edit"></Radio>
            <Radio id="publish-mode" label="Published"></Radio>
        </RadioGroup>
      </div>
      <div v-show="!isEditMode && !isPublishMode">
        <Tabs @on-click="onAdwordsTabClick" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
            <TabPane class="tab-pane capitalize"
              v-for="adFormat in adwordsAdFormats"
              :key="adFormat"
              :label="adwordsAdFormatLabel[adFormat]"
              :name="adwordsAdFormatLabel[adFormat]"
            >
            <div class="copy-headers">
                <Tooltip theme="light" :transfer="true">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
              <div class="ad-sheet-settings" v-if="showAdSettings">
                <adwords-adsheet-settings
                refs="adsheetSettings"
                :campaignType="selectedCampaignLauncherConfig.campaign.type"
                :adformatType="adFormat"
                @savedAdSheetSettings="saveAdSheetSettings($event, adFormat)"
                />
              </div>
              <AdwordsHotWrapper :ref="`${adFormat}HotTable`"
                :tab="adFormat"
                v-if="isCreateSheetDataCallCompletedForAdwords || !isAdwordsPublishTabSupported"
                :colHeaders="colHeaders[adFormat]"
                :creativeSpec="creativeSpec[adFormat]"
                :displayColHeaders="displayColHeaders[adFormat]"
                :requiredCols="requiredCols[adFormat]"
                :callToAction="callToAction[adFormat]"
                :generateCampaigns="generateCampaigns"
                :generateOldCampaigns="generateOldCampaigns"
                :customizedAdWordsColHeaders="customizedAdWordsColHeaders"
              >
              </AdwordsHotWrapper>
            </TabPane>
        </Tabs>
      </div>
      <div v-show="isEditMode">
        <Tabs @on-click="onAdwordsTabClick" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
            <TabPane class="tab-pane capitalize"
              v-for="adFormat in adwordsAdFormats"
              :key="adFormat"
              :label="adwordsAdFormatLabel[adFormat]"
              :name="adwordsAdFormatLabel[adFormat]"
            >
            <div class="copy-headers">
                <Tooltip theme="light" :transfer="true">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
              <!-- <div class="ad-sheet-settings" v-if="showAdSettings">
                <adwords-adsheet-settings
                refs="adsheetSettings"
                :campaignType="selectedCampaignLauncherConfig.campaign.type"
                :adformatType="adFormat"
                @savedAdSheetSettings="saveAdSheetSettings($event, adFormat)"
                />
              </div> -->
              <AdwordsEditHotWrapper :ref="`${adFormat}HotEditTable`"
                :tab="adFormat"
                :colHeaders='["valid", "HOT_Ad Status", "CampaignName"].concat(colHeaders[adFormat].slice(1))'
                :creativeSpec="creativeSpec[adFormat]"
                :displayColHeaders='["Valid Asset", "Status", "Campaign Name"].concat(displayColHeaders[adFormat].slice(1))'
                :requiredCols="requiredCols[adFormat]"
                :callToAction="callToAction[adFormat]"
                :generateCampaigns="generateCampaigns"
                :generateOldCampaigns="generateOldCampaigns"
                :customizedAdWordsColHeaders="customizedAdWordsColHeaders"
                :isEditDataFetchComplete="isEditTabDataFetchComplete"
              >
              </AdwordsEditHotWrapper>
            </TabPane>
        </Tabs>
      </div>
      <div v-show="isPublishMode">
        <Tabs @on-click="onAdwordsTabClick" :animated="false" type="card" class="tabs dx-tab-style hot-scroll">
            <TabPane class="tab-pane capitalize"
              v-for="adFormat in adwordsAdFormats"
              :key="adFormat"
              :label="adwordsAdFormatLabel[adFormat]"
              :name="adwordsAdFormatLabel[adFormat]"
            >
            <div class="copy-headers">
                <Tooltip theme="light" :transfer="true">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
              <AdwordsPublishHotWrapper :ref="`${adFormat}HotPublishTable`"
                :tab="adFormat"
                :colHeaders="colHeaders[adFormat]"
                :creativeSpec="creativeSpec[adFormat]"
                :displayColHeaders="displayColHeaders[adFormat]"
                :requiredCols="requiredCols[adFormat]"
                :callToAction="callToAction[adFormat]"
                :generateCampaigns="generateCampaigns"
                :generateOldCampaigns="generateOldCampaigns"
                :customizedAdWordsColHeaders="customizedAdWordsColHeaders"
                :isPublishDataFetchComplete="isDataFetchComplete"
              >
              </AdwordsPublishHotWrapper>
            </TabPane>
        </Tabs>
      </div>
    </template>
    <template v-else-if="selectedPublisher == publisher.AMS.SponsoredDisplay">
      <Tabs :animated="false" type="card" class="tabs dx-tab-style hot-scroll" name="amsSdTab">
          <TabPane
            v-for="adFormat in sdFormats"
            :key="adFormat"
            :label="adFormat"
            :name="adFormat"
            tab="amsSdTab"
          >
          <AmazonSDHotWrapper
          :ref="`amazonSDHotTable`"
          :sdtab ="adFormat"
          :showAudiencePopUp = "false"
          />
          </TabPane>
      </Tabs>
    </template>
    <template v-else-if="selectedPublisher == publisher.Moloco">
      <div class="edit-toggle">
        <RadioGroup v-model="adSheetMode" @on-change="adSheetModeChange" type="button" button-style="solid" size="small">
            <Radio id="create-mode" class="create-mode" label="Create"></Radio>
            <Radio id="edit-mode" label="Edit"></Radio>
            <Radio id="publish-mode" label="Published"></Radio>
        </RadioGroup>
      </div>
      <div v-show="!isPublishMode && !isEditMode">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll linkedIn-adsheet-tab">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="capitalize(adFormat)"
            :name="capitalize(adFormat)"
            :disabled="adFormatNeedsToBeDisabled(adFormat)"
          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers">
                <Tooltip theme="light" :transfer="true">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
            <MolocoHotWrapper :ref="`${adFormat}HotTable`"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :colHeaders='["HOT_Is Valid", "HOT_LineItemId", "HOT_TargetId"].concat(colHeaders[adFormat].slice(1))'
              :creativeSpec="creativeSpec[adFormat]"
              :displayColHeaders='["Valid", "Line Item", "Target"].concat(displayColHeaders[adFormat].slice(1))'
              :requiredCols="requiredCols[adFormat]"
            />
          </TabPane>
        </Tabs>
      </div>
      <div v-show="isEditMode">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll linkedIn-adsheet-tab">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="capitalize(adFormat)"
            :name="capitalize(adFormat)"
            :disabled="adFormatNeedsToBeDisabled(adFormat)"
          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers">
                <Tooltip theme="light">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
            <MolocoEditHotWrapper :ref="`${adFormat}HotEditTable`"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :creativeSpec="creativeSpec[adFormat]"
              :colHeaders='["HOT_Is Valid", "HOT_Campaign Name", "HOT_Adgroup Name", "HOT_Ad Id", "HOT_Status"].concat(colHeaders[adFormat].slice(1))'
              :displayColHeaders='["Valid", "Campaign Name", "Adgroup Name", "Ad Id", "Status"].concat(displayColHeaders[adFormat].slice(1))'
              :requiredCols="requiredCols[adFormat]"
              :creativeHeaders="creativeHeaders[adFormat]"
              :isEditDataFetchComplete="isEditTabDataFetchComplete"
            />
          </TabPane>
        </Tabs>
      </div>
      <div v-if="isPublishMode">
        <Tabs v-model="selectedTab" :animated="false" type="card" class="tabs dx-tab-style hot-scroll linkedIn-adsheet-tab">
          <TabPane class="tab-pane capitalize"
            v-for="adFormat in adFormats"
            :key="adFormat"
            :label="capitalize(adFormat)"
            :name="capitalize(adFormat)"
            :disabled="adFormatNeedsToBeDisabled(adFormat)"
          >
            <transition name="adsheet-alert">
              <div
                v-show="alert.show && alert.tab == adFormat"
                class="alert alert-warning"
                role="alert"
              >
                <button type="button" class="close" @click="alert.show = false">
                  ×
                </button>
                <div v-html="alert.message"></div>
              </div>
            </transition>
            <div class="copy-headers">
                <Tooltip theme="light" :transfer="true">
                <Button size="small" @click="copyHeaders(adFormat)">
                  <i class="fa fa-copy" />
                </Button>
                <span slot="content">Click to copy headers</span>
                </Tooltip>
              </div>
            <MolocoPublishedHotWrapper :ref="`${adFormat}HotPublishTable`"
              @onSetAdSheetAlert="setAdSheetAlert"
              :tab="adFormat"
              :colHeaders='["HOT_Is Valid", "HOT_LineItemId", "HOT_TargetId"].concat(colHeaders[adFormat].slice(1))'
              :creativeSpec="creativeSpec[adFormat]"
              :displayColHeaders='["Valid", "Line Item", "Target"].concat(displayColHeaders[adFormat].slice(1))'
              :requiredCols="requiredCols[adFormat]"
              :isDataFetchComplete="isDataFetchComplete"
            />
          </TabPane>
        </Tabs>
      </div>
    </template>
  </div>
</template>

<script>
import { tooltip } from "vue-strap";
import { mapState, mapMutations, mapGetters } from "vuex";
import {
  columnConfig,
  displayHeaderNameLookup,
  placementOptions
} from "./../columnConfig.js";
import { creativeSpec } from "./../creativeSpec.js";
import { Button, Tabs, TabPane, locale, Select, Option, Row, Col, Page, Radio, RadioGroup, Badge, Tooltip } from "iview";
import lang from "iview/dist/locale/en-US";
import FacebookHotWrapper from "./HotWrapper/Facebook.vue";
import FacebookEditHotWrapper from "./HotWrapper/FacebookEdit.vue";
import LinkedInHotWrapper from "./HotWrapper/LinkedIn.vue";
import DV360HotWrapper from "./HotWrapper/DV360.vue";
import TwitterHotWrapper from "./HotWrapper/Twitter.vue";
import AdwordsHotWrapper from "./HotWrapper/Adwords.vue";
import AdwordsEditHotWrapper from "./HotWrapper/AdwordsEdit.vue";
import AdwordsPublishHotWrapper from "./HotWrapper/AdwordsPublish.vue";
import FacebookCreativePreview from "./../BulkExport/Components/FacebookCreativePreview.vue";
import { APIService } from "./../ApiService.js";
import { EventBus } from "../EventBus.js";
import * as fbEnums from "../Constants/FacebookEnums.js";
import moment from 'moment';
import mixins from "../../../Mixins/copyToClipboard.js";
import AdSheetSettings from "./Facebook/AdSheetSettings.vue"
import AdwordsAdSheetSettings from "./AdWords/AdSheetSettings.vue"
import { adwordsEnums } from "../Constants/AdwordsEnums.js";
import FacebookPublishHotWrapper from "./HotWrapper/FacebookPublished.vue";
import { amsEnums } from "../Constants/AMSEnums.js";
import AmazonSDHotWrapper from "./HotWrapper/AmazonSD.vue";
import AmazonSpHotWrapper from "./HotWrapper/AmazonSp.vue";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";
import { promotionVersionEnums, promotionStatus } from "../Constants/PromotionEnums.js";
import ModalMessageMixin from "../../PromotionLaunchWizard/Mixins/ModalMessageMixin.js";
import MolocoHotWrapper from "./HotWrapper/Moloco.vue";
import MolocoPublishedHotWrapper from "./HotWrapper/MolocoPublished.vue";
import MolocoEditHotWrapper from "./HotWrapper/MolocoEdit.vue";
import { getAssetRequirements, multipleColumnsHeaderName } from "../Constants/AdwordsAdsheetSettingsRequirements.js"

locale(lang);

export default {
  name: "AdSheetWidget",
  mixins: [mixins, FacebookCampaignLauncherConfig, ModalMessageMixin],
  components: {
    Tabs,
    TabPane,
    Radio,
    RadioGroup,
    FacebookHotWrapper,
    FacebookEditHotWrapper,
    LinkedInHotWrapper,
    DV360HotWrapper,
    AdwordsHotWrapper,
    AdwordsEditHotWrapper,
    AdwordsPublishHotWrapper,
    FacebookCreativePreview,
    'facebook-adsheet-settings': AdSheetSettings,
    'adwords-adsheet-settings': AdwordsAdSheetSettings,
    TwitterHotWrapper,
    Select,
    Option,
    Row,
    "Row-Col": Col,
    Page,
    Button,
    "v-tooltip": tooltip,
    Tooltip,
    FacebookPublishHotWrapper,
    AmazonSDHotWrapper,
    AmazonSpHotWrapper,
    MolocoHotWrapper,
    MolocoPublishedHotWrapper,
    MolocoEditHotWrapper
  },
  data: function () {
    return {
      selectedTab: "Single",
      skipResetSelectedTab: false,
      creativeSpecWrapper: { noVal: true },
      creativeInPreview: 0,
      isCreativeInPreviewLanguageCustomized: false,
      accountId: "",
      hardcodedCardsCount: 10,
      bpTimeZone: null,
      colHeaders: {},
      displayColHeaders: {},
      showCreativeSetup: {},
      requiredCols: {},
      creativeSpec: {},
      creativeHeaders: {},
      callToAction: {},
      placementOptions: {},
      placementCustomizationHeaders: {},
      placementCustomizationConfigHeaders: {},
      languageCustomizationConfigHeaders: {},
      dynamicCreativeConfigHeaders: {},
      promoModeAutoFill: {},
      alert: {},
      fetchAlwaysCallback: null,
      saveAlwaysCallback: null,
      mediaPlanIdMapper: {},
      facebookSpecForPromo: {},
      productSetId: 0,
      fbEnums,
      failureReason: null,
      creativeSavedAt: null,
      SaveAmsTableData: false,
      extraPlacementCustomizationHeaders: null,
      optionalColumns: null,
      placementCustomizationSettings: null,
      rerendererKey: 0,
      isEditMode: false,
      adSheetMode: 'Create',
      isEditDataFetchComplete: false,
      targetsMappedToConfig: [],
      dpaCreativeToolSettings: null,
      dpaCreativeToolHeaders: null,
      adLabelDimensionSettings: [],
      placementCustomizationHeadersLocalVar: {},
      publishStageDataApiUrl: `${deltax.apiEndPoint}businessProfiles/${deltax.businessProfileId}/PublishStageData`,
      // adwordsAdFormats: adwordsEnums.adwordsAdFormats[this.selectedCampaignLauncherConfig.campaign.type],
      sdFormats: amsEnums.sdFormats,
      spFormats: amsEnums.spFormats,
      spAutoFormats: amsEnums.spAutoFormats,
      adwordsAdFormatLabel: adwordsEnums.adwordsAdFormatLabel,
      adwordsLabelToAdFormatMapper: adwordsEnums.adwordsLabelToAdFormatMapper,
      generateCampaigns: {},
      isPublishMode: false,
      SaveAmsSDTableData: false,
      SaveAmsSpTableData: false,
      currentAmsTab: '',
      languageCustomizationSettings: null,
      languageCustomizationHeaders: {},
      dynamicCreativeHeaders: null,
      dynamicCreativeSettings: null,
      languageHeaders: null,
      publishedDatafetchedForConfigId: 0,
      editDatafetchedForConfigId: 0,
      generateOldCampaigns: {},
      stopNavigationToSummaryPage: false,
      isDataFetchComplete: false,
      isEditTabDataFetchComplete: false,
      customizedAdWordsColHeaders: [],
      adwordsAdSheetSettings: null,
      isCreateSheetDataCallCompletedForFacebook: false,
      isCreateSheetDataCallCompletedForAdwords: false,
      labelsToBeApplied: []
    };
  },
  props: {
    fetchGroupedEntitiesURL: { type: String, default: "" },
    fetchAdWordsGroupedEntitiesURL: { type: String, default: "" },
    submissionId: 0
  },
  async created () {
    EventBus.$on("showNextStepClicked", async alwaysCb => {
      this.fetchAlwaysCallback = alwaysCb
      let validated = false
      if (!this.autoNavigateToNextStep) {
        if (this.selectedPublisher == this.publisher.Facebook) {
          validated = await this.validateLabels();
        } else {
          validated = true
        }
      }
      if (this.autoNavigateToNextStep || validated) {
        if (validated) {
          this.$store.commit("set_showCampaignLauncherError", false);
        }
        await this.ShowNextStep();
      } else {
        this.fetchAlwaysCallback();
      }
    });
    EventBus.$on("saveCreativeClicked", alwaysCb => {
      this.saveAlwaysCallback = alwaysCb
      this.SaveCreative();
    });
    EventBus.$on("publishClicked", () => {
      if (!this.isWizardFlow) {
        this.resetSheetData();
      }
    });
    EventBus.$on("app-fetchcampaignLauncherPublishDataId-for-Submission", (skipSaveEntities, submissionId) => {
      this.getCampaignLauncherPublishDataId(skipSaveEntities, submissionId);
    });
  },
  mounted () {
    const mediaPlans = JSON.parse(this.deltax.mediaPlans);
    mediaPlans.forEach(mediaPlan => {
      this.mediaPlanIdMapper[mediaPlan.Id] = mediaPlan
    })
    this.languageHeaders = ""
  },
  beforeDestroy () {
    EventBus.$off("showNextStepClicked");
    EventBus.$off("saveCreativeClicked");
    EventBus.$off("publishClicked");
    EventBus.$off("app-fetchcampaignLauncherPublishDataId-for-Submission");
  },
  computed: {
    ...mapState([
      "adFormats",
      "builtImageIdUrlMap",
      "imageIdUrlMap",
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "campaignLauncherAlertMessage",
      "campaignLauncherErrorMessage",
      "campaignLauncherConfigs",
      "creativeFields",
      "creativesToPublish",
      "mappedLineItems",
      "mediaPlanId",
      "previewableSpec",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "showCampaignLauncherAlert",
      "showCampaignLauncherError",
      "isOauthExpired",
      "deltax",
      "accounts",
      "selectedPublisher",
      "isPromotionMode",
      "promotionId",
      "promotionObject",
      "publisher",
      "showAdSheetLoader",
      "autoNavigateToNextStep",
      "adwordsEnums",
      "publishedCreativesByCampaignConfigId",
      "tableData",
      "exceptionLabels",
      "selectedObjectiveIdForPublisher",
      "currentAdSheet",
      "publishedCreatives",
      "previewableRowsCreativeSpec",
      "mediaPlanLineItems",
      "savedTargets",
      "AmsDataValid",
      "adLabelDimensionNomenclature",
      "labelDimensions",
      "initialLocations",
      "adGroupNameToIdentifierMapper",
      "createSheetCreatives",
      "isWizardFlow",
      "dbStores",
      "publishersSupportingNewLauncherFlow",
      "publishJson",
      "adwordsAdSheetSettingsInfo",
      "currentVersionOfGeneratedEntities",
      "editedCreativesToPublish",
      "editSheetCreatives",
      "fetchAdwordsEditTab",
      "fetchAdwordsPublishTab",
      "totalSheetCreatives",
      "facebookPosts",
      "instagramPosts",
      "isPostsFetched"
    ]),
    ...mapGetters(["businessProfileTimezone", "getFacebookNomenclature", "amsSheetDetails", "isLivePromotion"]),
    adwordsAdFormats () {
      if (this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.type) {
        let adFormats = JSON.parse(JSON.stringify(adwordsEnums.adwordsAdFormats[this.selectedCampaignLauncherConfig.campaign.type]));
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType["App"]) {
          if (this.selectedCampaignLauncherConfig.campaign.subtype && this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType['App Installs']) {
            adFormats.splice(2, 1);
            return adFormats;
          } else {
            adFormats.splice(1, 1);
            return adFormats;
          }
        } else {
          return adwordsEnums.adwordsAdFormats[this.selectedCampaignLauncherConfig.campaign.type]
        }
      } else {
        return [];
      }
    },
    renderAdSheet () {
      if (this.selectedPublisher == 5) {
        if (this.publishedCreatives && this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings.languageHeaders) {
          this.languageHeaders = this.publishedCreatives.adSheetSettings.languageHeaders;
        }
        if (this.publishedCreatives && this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings.adLabelDimensions) {
          this.adLabelDimensionSettings = this.publishedCreatives.adSheetSettings.adLabelDimensions;
        }
      }
      return [this.selectedFbObjective, this.creativeFields, this.selectedCampaignLauncherConfigId, this.extraPlacementCustomizationHeaders, this.dpaCreativeToolHeaders, this.adLabelDimensionSettings, this.languageHeaders, this.dynamicCreativeHeaders, this.adwordsAdSheetSettingsInfo].join();
    },
    displayNetworkEnabled () {
      return this.selectedCampaignLauncherConfig.campaign.networks &&
        this.selectedCampaignLauncherConfig.campaign.networks.includes(2);
    },
    getSelectedAccountInfo () {
      let accountId = this.selectedCampaignLauncherConfig.facebookAccountId;
      let accountName = null;
      this.accounts.forEach(item => {
        accountName = (item.AccountId === accountId) ? item.NickName : accountName;
      });
      return accountName + " (" + accountId + ")";
    },
    checkInfoViewToBeRendered () {
      if (this.selectedPublisher === this.publisher.Facebook) {
        return (this.showAdSheetLoader || this.selectedFbObjective === null || !this.builtImageIdUrlMap);
      } else if (this.selectedPublisher === this.publisher.LinkedIn || this.selectedPublisher === this.publisher.Twitter) {
        return (this.showAdSheetLoader || !this.selectedCampaignLauncherConfigId || !this.selectedObjectiveIdForPublisher);
      } else if (this.selectedPublisher === this.publisher.DV360) {
        return this.showAdSheetLoader || !this.selectedCampaignLauncherConfigId;
      } else if (this.selectedPublisher === this.publisher.Adwords) {
        return (!deltax.isAdwordsCampaignLaucherFlow || this.showAdSheetLoader || !this.selectedCampaignLauncherConfigId || (!this.isCreateSheetDataCallCompletedForAdwords && this.isAdwordsPublishTabSupported && !this.isPromotionMode));
      } else if (this.selectedPublisher === this.publisher.AMS.SponsoredProducts || this.selectedPublisher === this.publisher.AMS.SponsoredDisplay) {
        return (this.showAdSheetLoader || !this.selectedCampaignLauncherConfigId);
      } else if (this.selectedPublisher === this.publisher.Moloco) {
        return this.showAdSheetLoader || !this.selectedCampaignLauncherConfigId;
      }
      return true;
    },
    isObjectiveNotPresent () {
      if (this.selectedPublisher === this.publisher.Facebook) {
        return this.selectedFbObjective === null;
      }
      if (this.selectedPublisher == this.publisher.LinkedIn || this.selectedPublisher == this.publisher.Twitter || this.selectedPublisher == this.publisher.Moloco) {
        return this.selectedObjectiveIdForPublisher === null
      }
      if (this.selectedPublisher == this.publisher.DV360) {
        return !this.selectedCampaignLauncherConfigId;
      }
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        return !this.selectedCampaignLauncherConfigId;
      }
      if (this.selectedPublisher == this.publisher.Adwords) {
        return !this.selectedCampaignLauncherConfigId;
      }
      return false;
    },
    showAdSettings () {
      return this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Performance Max'] || (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Display'] && this.selectedTab == this.adwordsLabelToAdFormatMapper["Responsive Ad"]) ||
      (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['App'] && (this.selectedTab === "appEngagementAds" || this.selectedTab === "appAds")) ||
      (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Demand Gen'] && this.selectedTab != this.adwordsLabelToAdFormatMapper["AdGroups"]);
    },
    isAdwordsPerformanceMaxCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"];
    },
    isAwordsSearchCampaign () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Search"];
    },
    isAdwordsPublishTabSupported () {
      return (this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"] ||
           this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Search"]
      );
    },
    EditTabEnabled () {
      return this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"];
    }
  },
  watch: {
    renderAdSheet: {
      immediate: true,
      handler: function () {
        this.executeRenderLogic();
      }
    },
    selectedTab: function () {
      this.$emit("tabchange", this.selectedTab);
      if (this.selectedPublisher === this.publisher.LinkedIn) {
        this.set_currentAdSheet(this.selectedTab)
      }
    },
    '$route': function (to, from) {
      // if (from.path == '/summary' && this.$router.currentRoute.path == "/new") {
      //   this.isEditDataFetchComplete = false;
      //   setTimeout(() => {
      //      this.isEditDataFetchComplete = true;
      //   });
      // }
    },
    selectedCampaignLauncherConfig (value) {
      this.adSheetMode = 'Create'
      this.isEditMode = false
      this.isPublishMode = false
      this.isEditDataFetchComplete = false;
      this.extraPlacementCustomizationHeaders = null
      this.optionalColumns = null
      this.placementCustomizationSettings = null;
      this.dynamicCreativeSettings = null;
      this.dynamicCreativeHeaders = null;
      this.dpaCreativeToolHeaders = null;
      this.dpaCreativeToolSettings = null;
      this.$store.state.fetchAdwordsEditTab = true;
      this.$store.state.fetchAdwordsPublishTab = true;
      var self = this;
      this.$store.commit("set_AmsLoadNext", false);
      this.$store.commit("set_AmsDataValid", true);
      this.$store.commit("set_isSdCreated", false);
      this.$store.commit("set_isCreated", false);
      if (this.isDPAObjective(this.selectedCampaignLauncherConfig) ||
         (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) &&
         this.selectedCampaignLauncherConfig.campaign.productCatalog)) {
        APIService.getPromotedProductSetIdForGivenConfig(self.selectedCampaignLauncherConfigId)
          .then(response => {
            self.productSetId = response.data.id;
          })
          .catch(e => {
            console.log(e);
          });
      }
      if (self.mediaPlanId && self.selectedCampaignLauncherConfigId && !self.isPromotionMode) {
        if (self.selectedPublisher == 13) {
          APIService.getPublishData(self.mediaPlanId, self.selectedCampaignLauncherConfigId, 10)
            .then(response => {
              this.$store.commit("set_AmsPublishedData", response.data.data[0].lineItemTargetDetails)
            })
            .catch(() => {
              this.$Message.error({ background: true, content: "Error while fetching existing campaign information from AMS. Please reload and try again." });
            });
        } else if (self.selectedPublisher == self.publisher.Facebook) {
          this.getAdsheetData();
        } else if (self.selectedPublisher == self.publisher.Moloco) {
          this.getAdsheetData();
          if (this.autoNavigateToNextStep) {
            this.getCampaignLauncherPublishDataId(this.autoNavigateToNextStep)
          }
        } else if (self.selectedPublisher == self.publisher.Adwords && this.isAdwordsPublishTabSupported) {
          this.getAdsheetData();
        } else if (self.selectedPublisher) {
          APIService.getPublishData(self.mediaPlanId, self.selectedCampaignLauncherConfigId, self.selectedPublisher)
            .then(res => {
              var publishData = res.data.data;
              var publishJson = []
              if (publishData && publishData.publishJson) {
                publishJson = JSON.parse(publishData.publishJson)
              }
              self.$store.commit("set_publishJson", publishJson)
              self.isEditDataFetchComplete = true;
            })
            .catch(() => {
              self.$store.commit("set_publishJson", [])
              this.$Message.error({
                background: true,
                content: "Unable to fetch publish data."
              });
            });
        }
      }
      // enable create sheet in pmax without calling getAdsheetData() for promo flow
      if (self.isPromotionMode && self.selectedPublisher == self.publisher.Adwords && this.isAdwordsPublishTabSupported) {
        self.isCreateSheetDataCallCompletedForAdwords = true;
      }
    },
    creativeInPreview (value) {
      if (!value) {
        return;
      }
      this.updateAdPreviewCreativeSpec(value);
    },
    checkInfoViewToBeRendered (value) {
      if (!value && this.isWizardFlow) {
        EventBus.$emit("campainLauncherApp-adSheetDataFetchCompleted")
      }
    },
    "adwordsAdSheetSettingsInfo": {
      immediate: true,
      handler: function (val) {
        if (this.selectedPublisher === this.publisher.Adwords) {
          this.initializeColHeadersFromAdsheetSetting(val);
        }
      }
    },
    mappedLineItems () {
      this.executeRenderLogic();
    }
  },
  methods: {
    ...mapMutations([
      "set_adwordsEntitiesResponse",
      "set_promotionObject",
      "set_amsEntitiesResponse",
      "set_dv360EntitiesResponse",
      "set_autoNavigateToNextStep",
      "set_linkedInEntitiesResponse",
      "set_twitterEntitiesResponse",
      "set_currentAdSheet",
      "set_publishedCreatives",
      "set_previewableRowsCreativeSpec",
      "set_showAdSheetLoader",
      "set_currentVersionOfGeneratedEntities",
      "set_adLabelDimensionNomenclature",
      "setAdwordsLocations",
      "setAdwordsTargets",
      "setAdGroupMapper",
      "setAdGroupCampaignMapper",
      "set_adSheetCustomizationSettings",
      "set_facebookLanguages",
      "set_allCreativesInfo",
      "set_createSheetCreatives",
      "set_editSheetCreatives",
      "set_publishedSheetCreatives",
      "set_adCreativeMapping",
      "set_publishedCreativesByCampaignConfigId",
      "set_publishedCreativesInitialState",
      "set_molocoCampaignLauncherPublishDataId",
      "set_renderSubmissionSummary",
      "set_adWordsAdSheetSettings",
      "set_editedCreativesToPublish",
      "set_totalSheetCreatives",
      "set_connectedInstagramAccountId",
      "set_instagramPosts",
      "set_facebookPosts",
      "set_isPostsFetched"
    ]),
    initializeColHeadersFromAdsheetSetting (val) {
      if (val && Object.keys(val).length) {
        this.adwordsAdSheetSettings = val;
        this.customizedAdWordsColHeaders = [];
        this.customizedAdWordsColHeaders = this.getColHeadersFromAdSheetSettings(val);
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Performance Max']) {
          [this.colHeaders["assetGroups"], this.displayColHeaders["assetGroups"]] = this.buildColHeaders("assetGroups");
        }
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Display']) {
          [this.colHeaders["responsiveDisplayAds"], this.displayColHeaders["responsiveDisplayAds"]] = this.buildColHeaders("responsiveDisplayAds");
        }
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['App'] && this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType['App Installs']) {
          [this.colHeaders["appAds"], this.displayColHeaders["appAds"]] = this.buildColHeaders("appAds");
        } else if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['App'] && this.selectedCampaignLauncherConfig.campaign.subtype == adwordsEnums.campaignSubType['App Engagement']) {
          [this.colHeaders["appEngagementAds"], this.displayColHeaders["appEngagementAds"]] = this.buildColHeaders("appEngagementAds");
        }
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Demand Gen']) {
          let customizedColHeaders = this.getColHeadersFromAdSheetSettings(val);
          let adFormatsForGen = this.adwordsAdFormats;
          for (let demandGenAdFormat in adFormatsForGen) {
            let name = adFormatsForGen[demandGenAdFormat]
            this.customizedAdWordsColHeaders = customizedColHeaders[name] || [];
            [this.colHeaders[name], this.displayColHeaders[name]] = this.buildColHeaders(name);
          }
        }
      }
    },
    async validateLabels () {
      // we get labels from the DB and store the label name as key and labelTypeId as an value in the existedLabelMap
      // we use existedLabelMap to check which Label belongs to which LabelDimesnion
      var existedLabelMap = {}

      // labels from the adsheet are fetched and stored as label name as a key and labelTypeId's(array) as an value in the LabelMap
      // we use labelMap to keep track of all the labelDimension's for a particular label
      // so if label in the LabelMap has many labelDimension's we flag it as Duplicate
      var labelMap = {}

      // we use labelStruture to aggregate all the info of all rows and LabelDimension's group by labels
      // ex - {"label1" : {row0 : [LD1, LD2]}, {row1 : [LD3, LD4]}}
      // if a label is duplicate, we can easily display all the LabelDimension's and rows information as errors for that particular label
      var labelStructure = {}
      this.getPreviewableRows();
      this.previewableSpec.forEach((ad, index) => {
        if (ad.hotRowId.includes("single")) {
          ad.labels.forEach((label) => {
            const labelName = label.Name;
            const labelTypeId = label.LabelTypeId;
            labelMap[labelName] = labelMap[labelName] || [];
            labelStructure[labelName] = labelStructure[labelName] || {};
            if (!labelMap[labelName].includes(labelTypeId)) {
              labelMap[labelName].push(labelTypeId);
            }
            labelStructure[labelName][index] = labelStructure[labelName][index] || [];
            labelStructure[labelName][index].push({ labelTypeId: labelTypeId, adName: ad["AdName"] })
          })
        }
      })
      if (!Object.keys(labelStructure).length > 0) {
        return true
      }
      var existingLabels = [];
      existingLabels = await APIService.getLabelsByLabelNames(Object.keys(labelStructure))
      this.labelsToBeApplied = existingLabels.map(label => label.id);
      existingLabels.forEach(label => {
        existedLabelMap[label.name] = label.labelTypeId;
      })

      // validate the label's if a label is invalid add the reason
      var reasons = [];
      const keysArray = Object.keys(labelMap);
      for (const label of keysArray) {
        var _isLabelReserved = existedLabelMap[label] ? !(labelMap[label].includes(existedLabelMap[label]) && labelMap[label].length == 1) : false
        if ((labelMap[label].length > 1 || _isLabelReserved)) {
          if (_isLabelReserved) {
            reasons.push(...this.convertRowLabelObjectToString(label, labelStructure[label], labelStructure, existedLabelMap[label]));
          } else {
            reasons.push(...this.convertRowLabelObjectToString(label, labelStructure[label], labelStructure));
          }
        }
      }

      if (reasons.length > 0) {
        this.$store.commit("set_showCampaignLauncherError", true);
        this.$store.commit(
          "set_campaignLauncherErrorMessage",
          [`<span style="padding: 0; margin: 0px 0px 4px 0px;text-decoration: underline;">Ad format: single</span><br/>`, ...reasons]
        );
        return false
      }
      return true
    },
    convertRowLabelObjectToString (label, labelStructureObject, labelStructure, existingLabelDimension) {
      const resultArray = [];
      resultArray.push(`<span style="padding: 0; margin: 5px;">Label: ${label}</span><br/>`);
      if (existingLabelDimension) {
        resultArray.push(`<span style="padding: 0;margin:0px 15px;">Belongs to ${this.labelDimensions[existingLabelDimension].name}, used with incorrect dimension</span><br/>`);
      } else {
        resultArray.push(`<span style="padding: 0;  margin: 0px 15px;">Used with multiple dimensions</span><br/>`);
      }
      const keysArray = Object.keys(labelStructureObject);
      for (const row of keysArray) {
        var adName = labelStructureObject[row][0].adName
        var labelDimensions = labelStructureObject[row].map(LD => this.labelDimensions[LD.labelTypeId].name).join(',');
        resultArray.push(`<span style="padding: 0; margin: 0px 15px;">At row ${parseInt(row) + 1} (Ad Name: ${adName}) :${labelDimensions}</span><br/>`);
      }
      return resultArray;
    },
    addColumns (colHeader) {
      return colHeader.concat(["Status", "Error"])
    },
    tabSwitch (name) {
      this.currentAmsTab = name;
    },
    getAmsTabFormats () {
      let amsSpFormats = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config).campaign.targetingType == 1 ? amsEnums.spFormats : amsEnums.spAutoFormats;
      return amsSpFormats;
    },
    onAdwordsTabClick (name) {
      if (this.selectedTab == "adGroups") {
        let rows = this.$refs[`adGroupsHotTable`] ? this.$refs[`adGroupsHotTable`][0].getAllRows() : [];
        if (this.isAwordsSearchCampaign) {
          let publishedRows = this.$refs[`adGroupsHotPublishTable`] ? this.$refs[`adGroupsHotPublishTable`][0].getAllRows() : [];
          rows = [...rows, ...publishedRows];
        }
        var adGroupIdentifierMapper = {};
        if (rows.length) {
          rows = rows.filter(row => row.adgroup && row.Identifier);
        }
        rows.forEach(row => {
          adGroupIdentifierMapper[row.adgroup] = row.Identifier;
        });
        this.$store.commit("setAdGroupMapper", adGroupIdentifierMapper);
        let adGroupCampaignMapper = {};
        rows.forEach(row => {
          adGroupCampaignMapper[row.adgroup] = row.campaign;
        });
        this.$store.commit("setAdGroupCampaignMapper", adGroupCampaignMapper);
      }
      this.selectedTab = this.adwordsLabelToAdFormatMapper[name];
    },
    isSheetSaveSupported () {
      return [this.publisher.Facebook, this.publisher.Twitter, this.publisher.LinkedIn, this.publisher.DV360].includes(this.selectedPublisher)
    },
    copyHeaders (adFormat) {
      let textarea = document.createElement("textarea")
      let headers = [...this.displayColHeaders[adFormat]]
      let configObj = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      if (this.adSheetMode == 'Published' && configObj && configObj.hasOwnProperty("searchEngineTypeId") && configObj.searchEngineTypeId == 5) {
        headers = this.addColumns(headers)
      }
      if (this.adSheetMode == 'Edit' && this.selectedPublisher == this.publisher.Adwords) {
        headers = this.addCampaignColumn(headers);
      }
      textarea.textContent = headers.join('\t')
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    adSheetModeChange (value) {
      if (value == 'Edit') {
        this.isEditMode = true;
        this.isPublishMode = false;
        setTimeout(() => { if (this.isEditMode) { $("#edit-mode").click(); } }, 500);
        this.getAdsheetData();
      } else if (value == 'Published') {
        this.isPublishMode = true;
        this.isEditMode = false;
        setTimeout(() => { if (this.isPublishMode) { $("#publish-mode").click(); } }, 500);
        this.getAdsheetData();
      } else {
        this.isEditMode = false;
        this.isPublishMode = false;
        setTimeout(() => { if (!this.isEditMode && !this.isPublishMode) { $("#create-mode").click(); } }, 500);
      }
    },
    updateAdPreviewCreativeSpec (pageNumber) {
      var newCreativeToBePreviewed = this.previewableRowsCreativeSpec.filter((e, i) => {
        return pageNumber == i + 1;
      })[0];
      this.creativeSpecWrapper = newCreativeToBePreviewed.spec;
      this.isCreativeInPreviewLanguageCustomized = newCreativeToBePreviewed.languageCustomization
    },
    setAdSheetAlert (alertObj) {
      this.alert = alertObj
    },
    capitalize (sentence) {
      return sentence
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    showPlacementCustomizationSettings (adFormat) {
      return this.checkIfCreativeSetup(adFormat)
    },
    showPreview ({ newSpec, creativeIndex, accountId, allPreviewableRowsSpec }) {
      if (this.isPromotionMode) {
        this.creativeSpecWrapper = newSpec;
        if (this.isDPAObjective(this.selectedCampaignLauncherConfig) ||
          (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) &&
            this.selectedCampaignLauncherConfig.campaign.productCatalog)) {
          this.creativeSpecWrapper["creative"]["product_set_id"] = this.productSetId;
        }
        this.accountId = accountId;
        $("#preview-modal").modal("show");
      } else {
        this.creativeInPreview = 0;
        var self = this;
        this.$nextTick(function () {
          if (self.isDPAObjective(self.selectedCampaignLauncherConfig)) {
            // this.creativeSpecWrapper["creative"]["product_set_id"] = this.productSetId;
            for (var i = 0; i < allPreviewableRowsSpec.length; i++) {
              allPreviewableRowsSpec[i]["spec"]["creative"]["product_set_id"] = self.productSetId;
            }
          }
          self.accountId = accountId;
          self.set_previewableRowsCreativeSpec(allPreviewableRowsSpec);
          self.creativeInPreview = creativeIndex;
          $("#preview-modal").modal("show");
        })
      }
    },
    checkIfCreativeSetup (adFormat) {
      /*
      BuyType
      1: Auction
      4: Rnf
      */
      if (this.selectedPublisher != this.publisher.Facebook) {
        return false
      }
      let placementCustomization = this.getPlacementCustomizations(this.selectedCampaignLauncherConfig)
      let dynamicCreative = this.getDynamicCreatives(this.selectedCampaignLauncherConfig)
      if (
        this.selectedCampaignLauncherConfig.campaign.buyType == 1 &&
        (this.selectedCampaignLauncherConfig.adset.destination ==
          fbEnums.destinationType.Page ||
          this.selectedCampaignLauncherConfig.adset.destination ==
          fbEnums.destinationType.App ||
            this.isVideoViewsObjective(this.selectedCampaignLauncherConfig) ||
              this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) ||
                this.isBrandAwarenessObjective(this.selectedCampaignLauncherConfig) ||
                this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig))
      ) {
        for (let obj of [placementCustomization, dynamicCreative]) {
          if ((obj || []).includes(adFormat)) {
            return true
          }
        }
      } else if (this.selectedCampaignLauncherConfig.campaign.buyType == 4) {
        for (let obj of [placementCustomization, dynamicCreative]) {
          if ((obj || []).includes(adFormat)) {
            return true
          }
        }
      }
      return false
    },
    checkIfLanguageCustomizationSupported (adFormat) {
      if (this.selectedPublisher != this.publisher.Facebook) {
        return false
      }
      let languageCustomizations = this.getLanguageCustomizations(this.selectedCampaignLauncherConfig)
      if (this.selectedCampaignLauncherConfig.campaign.buyType == 1) {
        for (let obj of [languageCustomizations]) {
          if ((obj || []).includes(adFormat)) {
            return true
          }
        }
      }
      return false
    },
    buildColHeaders (adFormat) {
      let column = columnConfig(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)[adFormat]
      var colHeaders = JSON.parse(JSON.stringify(column['base']));
      if (this.selectedPublisher === this.publisher.Adwords && this.selectedCampaignLauncherConfig && [adwordsEnums.campaignType.Display, adwordsEnums.campaignType.App, adwordsEnums.campaignType['Demand Gen']].includes(this.selectedCampaignLauncherConfig.campaign.type)) {
        if (adFormat === 'adGroups') {
          colHeaders.splice(3)
        }
      }
      if (this.selectedPublisher === this.publisher.Adwords && column.isAdsheetSettingSupported && this.customizedAdWordsColHeaders.length) {
        colHeaders = this.processAdwordsColHeaders(colHeaders, this.customizedAdWordsColHeaders);
      }
      // for facebook add these columns. For google it is not needed.
      if (this.isLivePromotion && this.selectedPublisher !== this.publisher.Adwords) {
        colHeaders = ["HOT_Preview Ad", "HOT_Ad Status"].concat(colHeaders.slice(1));
      }
      let isFbEngineType = false;
      // remove line item fractions column in promo mode
      if (this.isPromotionMode) {
        let self = this;
        colHeaders = colHeaders.reduce(function (headers, currentHeader) {
          if (!(currentHeader == "HOT_LineItemFractions" || self.isAdvantageHeader(currentHeader))) {
            headers.push(currentHeader);
          }
          return headers;
        }, [])

        let configObj = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
        if (configObj && configObj.hasOwnProperty("searchEngineTypeId") && configObj.searchEngineTypeId == 5) {
          isFbEngineType = true;
        }
        if (isFbEngineType) {
          colHeaders.push("HOT_TargetClusters");
        }
        // remove line item column in promo mode for adwords
        if (this.selectedPublisher === this.publisher.Adwords) {
          let index = colHeaders.indexOf("lineItem");
          if (index !== -1) {
            colHeaders.splice(index, 1);
          }
          colHeaders.push("locations");
        }
      }
      var isCreativeSetupEnabled = this.checkIfCreativeSetup(adFormat)
      var isLanguageCustomizationEnabled = this.checkIfLanguageCustomizationSupported(adFormat)
      if (colHeaders && this.optionalColumns && isCreativeSetupEnabled) {
        colHeaders = this.addOptionalColumnNextToMainColumn(colHeaders, this.optionalColumns)
      }
      let headerLookUp = displayHeaderNameLookup(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)
      if (this.isPromotionMode && isFbEngineType) {
        headerLookUp["HOT_TargetClusters"] = "Target Clusters"
      } else if (this.isPromotionMode && this.selectedPublisher === this.publisher.Adwords) {
        headerLookUp["locations"] = "Locations"
      }
      let displayColHeaders = colHeaders.map(header => {
        if (headerLookUp[header]) {
          return headerLookUp[header]
        } else if (headerLookUp[header.split(" - ")[0]]) {
          return headerLookUp[header.split(" - ")[0]] + " - " + header.split(" - ")[1]
        } else {
          console.log("Column not present in mapper and doesn't contains dynamic generated header", header)
        }
      }).filter(header => header)

      if (this.extraPlacementCustomizationHeaders && isCreativeSetupEnabled) {
        colHeaders.push(...this.extraPlacementCustomizationHeaders.map(x => "HOT_" + x))
        displayColHeaders.push(...this.extraPlacementCustomizationHeaders)
      }

      if (this.dynamicCreativeHeaders && isCreativeSetupEnabled) {
        colHeaders.push(...this.dynamicCreativeHeaders.map(x => "HOT_" + x))
        displayColHeaders.push(...this.dynamicCreativeHeaders)
      }

      if (this.dpaCreativeToolHeaders && this.isDPAObjective(this.selectedCampaignLauncherConfig) && ["single", "carousel"].includes(adFormat)) {
        colHeaders.push(...this.dpaCreativeToolHeaders.map(x => "HOT_" + x))
        displayColHeaders.push(...this.dpaCreativeToolHeaders)
      }

      if (this.languageCustomizationHeaders && this.languageCustomizationHeaders[adFormat] && isLanguageCustomizationEnabled) {
        colHeaders.push(...this.languageCustomizationHeaders[adFormat].map(x => "HOT_" + x))
        displayColHeaders.push(...this.languageHeaders)
      }

      let ldColHeaders = this.getLabelDimensionColumns();
      colHeaders.push(...ldColHeaders.map(x => "HOT_LD_" + x));
      displayColHeaders.push(...ldColHeaders);

      let cards = []
      if (column['card'].length > 0) {
        for (let i = 0; i < this.hardcodedCardsCount; i++) {
          displayColHeaders.push(
            ...column["card"].map(x => `${headerLookUp[x]} ${i + 1}`)
          );
          cards.push(...column["card"].map(x => `${x} ${i + 1}`));
        }
      }
      return [colHeaders.concat(cards), displayColHeaders];
    },
    isAdvantageHeader (currentHeader) {
      return currentHeader == "HOT_Standard_Enhancements" || currentHeader == "HOT_Image_Enhancements" || currentHeader == "HOT_3d_Animation" || currentHeader == "HOT_Music";
    },
    addOptionalColumnNextToMainColumn (mainSheetColumns, optionalColumns) {
      /*
      mainSheetColumns: [
        "HOT_Preview Ad",
        "HOT_Ad Name",
        "HOT_Link",
        "HOT_Call To Action",
        "HOT_Image/Video",
        "HOT_Ad Title",
        "HOT_Display Link",
        "HOT_Text",
        "HOT_Description",
        "HOT_Url Tags",
        "HOT_StartDate",
        "HOT_EndDate",
        "HOT_StartTime",
        "HOT_EndTime",
        "HOT_Targets",
        "HOT_Locations"
      ]
      optionalColumn : [
          "HOT_Ad Title",
          "HOT_Ad Title - Optional 1",
          "HOT_Ad Title - Optional 2"
      ]
      */
      if (mainSheetColumns && optionalColumns) {
        /* we are reversing because in above listed case post splice the output becomes
         HOT_Ad Title, HOT_Ad Title - Optional 2, HOT_Ad Title - Optional 1
         As we are finding the postion of HOT_Ad Title so everytime we would get index of 6 in above case.
        */

        optionalColumns.reverse()
        var existingColumns = mainSheetColumns
        optionalColumns.forEach(option => {
          if (!existingColumns.includes(option)) {
            // for eg option is HOT_Ad Title - Optional 1 we need to find where is the main column in mainSheetColumn
            var similarValue = option.split(" - ")
            if (similarValue[0]) {
              var position = existingColumns.indexOf(similarValue[0])

              // if not found so position wouuld be -1 then put it at the end else, splice and put value in between
              if (position < 0) {
                existingColumns.push(option)
              }
              existingColumns.splice(position + 1, 0, option)
            }
          }
        })
      }
      return existingColumns
    },
    getLabelDimensionColumns () {
      let labelDimensionCols = [];
      if (this.selectedPublisher === this.publisher.Facebook) {
        if (Array.isArray(this.adLabelDimensionSettings)) {
          this.adLabelDimensionSettings.forEach(ldId => {
            if (ldId in this.labelDimensions) {
              labelDimensionCols.push(this.labelDimensions[ldId].name);
            }
          })
        }
      }
      return labelDimensionCols;
    },
    buildRequiredCols (adFormat) {
      let column = columnConfig(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)[adFormat]
      let numberOfCardCols = column["card"].length * this.hardcodedCardsCount;
      let baseLength = this.colHeaders[adFormat].length - numberOfCardCols;

      return {
        base: new Set([...column["requiredBase"]]),
        card: new Set(column["requiredCard"]),
        baseLength,
        cardLength: column["card"].length
      };
    },
    buildCreativeSpec (adFormat) {
      let creativeSpecObj = creativeSpec(
        this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config, adFormat
      );
      if (this.isDPAObjective(this.selectedCampaignLauncherConfig) && ["single", "carousel"].includes(adFormat)) {
        if (this.dpaCreativeToolSettings && creativeSpecObj.frame) {
          let layerSpec = [{
            layer_type: "image",
            image_source: "catalog"
          }];
          if (this.dpaCreativeToolSettings["Frame"]) {
            layerSpec.push(creativeSpecObj.frame)
          }
          if (this.dpaCreativeToolSettings["Catalogue Information"]) {
            layerSpec.push(creativeSpecObj.catalogueInformation)
          }
          if (layerSpec.length > 1) {
            creativeSpecObj.creative.object_story_spec.template_data["image_layer_specs"] = layerSpec;
          }
        }
        return { creative: creativeSpecObj.creative }
      }
      return creativeSpecObj;
    },
    buildCreativeHeaders (adFormat) {
      let column = columnConfig(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)[adFormat]
      return column["creativeHeader"];
    },
    getAllRows () {
      let newRows = {};
      if (this.selectedPublisher != this.publisher.Adwords) {
        this.adFormats.forEach(adFormat => { // single, carousel etc
          let unpublishedRows = this.$refs[`${adFormat}HotTable`] && this.$refs[`${adFormat}HotTable`][0] ? this.$refs[`${adFormat}HotTable`][0].getAllRows() : []
          // let publishedRows = this.$refs[`${adFormat}HotTablePublish`] ? this.$refs[`${adFormat}HotTablePublish`][0].getAllRows() : []
          newRows[adFormat] = [...unpublishedRows];
        });
      } else {
        this.adwordsAdFormats.forEach(adFormat => { // ["adGroups", "keywords", "responsiveAds", "textAds"]
          let rows = this.$refs[`${adFormat}HotTable`] && this.$refs[`${adFormat}HotTable`][0] ? this.$refs[`${adFormat}HotTable`][0].getAllRows() : [];
          newRows[adFormat] = rows;
        });
      }
      if (this.placementCustomizationSettings) {
        newRows["adSheetSettings"] = this.placementCustomizationSettings
      } else if (this.dpaCreativeToolSettings) {
        newRows["adSheetSettings"] = this.dpaCreativeToolSettings
      } else if (this.dynamicCreativeSettings) {
        newRows["adSheetSettings"] = this.dynamicCreativeSettings
      } else if (this.languageCustomizationSettings) {
        newRows["adSheetSettings"] = this.languageCustomizationSettings
        this.set_adSheetCustomizationSettings(this.languageCustomizationSettings)
      }
      newRows.adSheetSettings = {
        ...(newRows.adSheetSettings || {}),
        adLabelDimensions: this.adLabelDimensionSettings
      }
      if (this.selectedPublisher == this.publisher.Adwords) {
        newRows["adSheetSettings"] = this.adwordsAdSheetSettings;
        if (newRows.adSheetSettings && newRows.adSheetSettings.adLabelDimensions) {
          delete newRows.adSheetSettings.adLabelDimensions;
        }
        this.set_adWordsAdSheetSettings(this.adwordsAdSheetSettings)
      }
      return newRows;
    },
    getEditTabRows (editedRowsOnly = true) {
      let newRows = {};
      if (this.selectedPublisher === this.publisher.Moloco) {
        this.adFormats.forEach(adFormat => {
          let editTabRows = this.$refs[`${adFormat}HotEditTable`] && this.$refs[`${adFormat}HotEditTable`][0] ? this.$refs[`${adFormat}HotEditTable`][0].getAllRows() : []
          newRows[adFormat] = [...editTabRows];
        });
      } else if (this.selectedPublisher === this.publisher.Adwords) {
        this.adwordsAdFormats.forEach(adFormat => {
          let editTabRows = this.$refs[`${adFormat}HotEditTable`] && this.$refs[`${adFormat}HotEditTable`][0] ? this.$refs[`${adFormat}HotEditTable`][0].getAllRows() : []
          if (editTabRows && editTabRows.length) {
            newRows[adFormat] = editedRowsOnly ? editTabRows.filter(row => row["isEdited"] === true) : editTabRows;
          }
        });
      }
      return newRows;
    },
    getPublishTabRows () {
      let newRows = {};
      if (this.selectedPublisher === this.publisher.Adwords) {
        this.adwordsAdFormats.forEach(adFormat => {
          let unpublishedRows = this.$refs[`${adFormat}HotPublishTable`] && this.$refs[`${adFormat}HotPublishTable`][0] ? this.$refs[`${adFormat}HotPublishTable`][0].getAllRows() : []
          newRows[adFormat] = [...unpublishedRows];
        });
      }
      return newRows;
    },
    getPreviewableRows () {
      let newSpecs = [];
      let newRows = {};
      this.facebookSpecForPromo = {};
      if (this.selectedPublisher != this.publisher.Adwords) {
        this.adFormats.forEach(adFormat => { // single, carousel etc
          let [specs, rows] = this.$refs[`${adFormat}HotTable`] && this.$refs[`${adFormat}HotTable`][0] ? this.$refs[`${adFormat}HotTable`][0].getPreviewableRows() : [[], []]
          newSpecs.push(...specs)
          newRows[adFormat] = rows
          this.facebookSpecForPromo[adFormat] = specs
        })
      } else {
        let self = this;
        this.adwordsAdFormats.forEach(adFormat => { // ["adGroups", "keywords", "responsiveAds", "textAds"]
          let rows = self.$refs[`${adFormat}HotTable`] && self.$refs[`${adFormat}HotTable`][0] ? self.$refs[`${adFormat}HotTable`][0].getPreviewableRows() : []
          if (adFormat == "responsiveAds" || adFormat == "textAds" || adFormat == "callAds" || adFormat == "responsiveDisplayAds" || adFormat == "appAds" || adFormat == "appEngagementAds" || adFormat == "discoveryCarouselAds" || adFormat == "discoveryImageAds" || adFormat == "discoveryVideoAds") {
            if (!newRows["Ads"]) {
              newRows["Ads"] = [];
            }
            newRows["Ads"] = newRows["Ads"].concat(rows);
          } else {
            newRows[adFormat] = rows;
          }
        });
      }
      if (this.isPromotionMode && this.selectedPublisher == this.publisher.Facebook) {
        this.setAdSheetSettingsInHotSpec(newRows)
      }
      this.$store.commit("set_previewableSpec", newSpecs)
      this.$store.commit("set_creativesToPublish", newRows)
    },
    getEditedRows () {
      let editedSpecs = [];
      let editedRows = {};
      this.adFormats.forEach(adFormat => { // single, carousel etc
        let [specs, rows] = []
        if (this.$refs[`${adFormat}HotTableEdit`] && this.$refs[`${adFormat}HotTableEdit`][0]) {
          [specs, rows] = this.$refs[`${adFormat}HotTableEdit`][0].getEditedRows()
        } else {
          [specs, rows] = [null, null]
        }
        if (specs) {
          editedSpecs.push(...specs)
        }
        editedRows[adFormat] = rows
      })
      return { specs: editedSpecs, rows: editedRows }
    },
    getCreativeStructure () {
      let newSpecs = [];
      let newRows = {};
      this.facebookSpecForPromo = {};
      this.adFormats.forEach(adFormat => { // single, carousel etc
        var data = this.tableData[adFormat].filter(x => x[0] == "true")
        let [specs, rows] = this.$refs[`${adFormat}HotTable`][0].getCreativeStructure(data)
        newSpecs.push(...specs)
        newRows[adFormat] = rows
        this.facebookSpecForPromo[adFormat] = specs
      })
      this.$store.commit("set_previewableSpec", newSpecs)
      this.$store.commit("set_creativesToPublish", newRows)
    },
    async showAdwordsNextStep () {
      this.$store.commit("set_isCreationFlow", true);
      if (this.autoNavigateToNextStep) {
        let countOfHOTComponents = this.adwordsAdFormats.filter(e => (this.$refs[`${e}HotTable`] && this.$refs[`${e}HotTable`].length)).length;
        while (countOfHOTComponents != this.adwordsAdFormats.length) {
          console.log("waiting for hot wrapper...");
          await this.sleep(1000);
          countOfHOTComponents = this.adwordsAdFormats.filter(e => (this.$refs[`${e}HotTable`] && this.$refs[`${e}HotTable`].length)).length;
        }

        // if pmax campaign loads the edit tab
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Performance Max']) {
          this.adSheetModeChange("Edit");
          let countOfHOTEditComponents = this.adwordsAdFormats.filter(e => (this.$refs[`${e}HotEditTable`] && this.$refs[`${e}HotEditTable`].length)).length;
          while (countOfHOTEditComponents != this.adwordsAdFormats.length) {
            console.log("waiting for edit hot wrapper...");
            await this.sleep(1000);
            countOfHOTEditComponents = this.adwordsAdFormats.filter(e => (this.$refs[`${e}HotEditTable`] && this.$refs[`${e}HotEditTable`].length)).length;
          }
          // wait untill edit sheet data is fetched
          while (!this.isEditTabDataFetchComplete) {
            await this.sleep(1000);
          }
          this.adSheetModeChange("Create");
        }
      }
      this.getPreviewableRows();
      if (this.isPromotionMode) {
        await this.processAdwordsPromotion();
      }
      if (!this.stopNavigationToSummaryPage) {
        this.saveAndGenerateEntities();
      }
    },
    showAMSSpNextStep () {
      this.SaveAmsTableData = true;
      if (this.AmsDataValid) {
        this.$store.commit("set_isCreationFlow", true);
        let parsedData = JSON.parse(this.tableData);
        var requestData = {
          requestId: parsedData.requestId,
          amsEntities: parsedData.amsEntities
        };
        var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData/" + parsedData.requestId
        APIService.PutCampaignLauncherRequestData(
          url,
          requestData
        ).then(response => {
          this.amsSuccessCallback(response)
        }).catch((response) => {
          this.fetchErrorCallback(response)
        }).finally(this.fetchAlwaysCallback);
      } else {
        this.fetchAlwaysCallback();
      }
    },
    showAMSNextStep () {
      this.SaveAmsTableData = true;
      if (this.AmsDataValid) {
        this.$store.commit("set_isCreationFlow", true);
        var requestData = {
          configId: this.selectedCampaignLauncherConfigId,
          mediaPlanId: this.mediaPlanId,
          creativeData: this.tableData
        };
        var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData"
        APIService.PostCampaignLauncherRequestData(
          url,
          requestData
        ).then(response => {
          this.amsSuccessCallback(response)
        }).catch((response) => {
          this.fetchErrorCallback(response)
        }).finally(this.fetchAlwaysCallback);
      } else {
        var message = "Please fill in the Adgroup or Keyword or ASIN targeting information to proceed.";
        this.$Message.error({ background: true, content: message });
        this.fetchAlwaysCallback();
      }
    },
    showDV360NextStep () {
      this.$store.commit("set_isCreationFlow", true);
      if (!this.autoNavigateToNextStep) {
        this.getPreviewableRows();
      }
      var requestData = {
        configId: this.selectedCampaignLauncherConfigId,
        mediaPlanId: this.mediaPlanId,
        businessProfilesSearchEngineId: this.businessProfilesSearchEngineId,
        creativeData: JSON.stringify(this.previewableSpec)
      };
      this.SaveCreative(true, this.autoNavigateToNextStep);
      var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData"
      APIService.PostCampaignLauncherRequestData(
        url,
        requestData
      ).then(response => {
        this.DV360SuccessCallback(response)
      }).catch((response) => {
        this.fetchErrorCallback(response)
      }).finally(this.fetchAlwaysCallback);
    },
    showLinkedInNextStep () {
      this.$store.commit("set_isCreationFlow", true);
      if (!this.autoNavigateToNextStep) {
        this.getPreviewableRows();
      }
      var requestData = {
        configId: this.selectedCampaignLauncherConfigId,
        mediaPlanId: this.mediaPlanId,
        creativeData: JSON.stringify(this.previewableSpec)
      };
      this.SaveCreative(true, this.autoNavigateToNextStep);
      var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData"
      APIService.PostCampaignLauncherRequestData(
        url,
        requestData
      ).then(response => {
        this.LinkedInSuccessCallback(response)
      }).catch((response) => {
        this.fetchErrorCallback(response)
      }).finally(this.fetchAlwaysCallback);
      // this.$store.commit("SET_SHOW_PREVIEW", true);
    },
    showTwitterNextStep () {
      this.$store.commit("set_isCreationFlow", true);
      if (!this.autoNavigateToNextStep) {
        this.getPreviewableRows();
      }
      var requestData = {
        configId: this.selectedCampaignLauncherConfigId,
        mediaPlanId: this.mediaPlanId,
        creativeData: JSON.stringify(this.previewableSpec)
      };
      this.SaveCreative(true, this.autoNavigateToNextStep);
      var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData"
      APIService.PostCampaignLauncherRequestData(
        url,
        requestData
      ).then(response => {
        this.twitterSuccessCallback(response)
      }).catch((response) => {
        this.fetchErrorCallback(response)
      }).finally(this.fetchAlwaysCallback);
    },
    async processPromotion (editedRows, selectedConfig) {
      let promoObj = this.promotionObject;
      promoObj.creativeJson["facebook"] = {
        facebookSpec: JSON.stringify(this.previewableSpec),
        handsonSpec: JSON.stringify(this.creativesToPublish)
      }

      if (promoObj && this.creativesToPublish["existing posts"]) {
        this.creativesToPublish["existing posts"].forEach(post => {
          post.HOT_IsNotPublished = false
        });
      }
      // Replace imageId DXC-xyz with image URL
      let handsonSpec = JSON.parse(JSON.stringify(this.creativesToPublish))
      Object.values(handsonSpec).forEach(adFormatRows => {
        if (adFormatRows && Array.isArray(adFormatRows)) {
          adFormatRows.forEach(row => {
            for (let [colName, cellData] of Object.entries(row)) {
              if (colName.startsWith("HOT_Image/Video") &&
              String(cellData).startsWith("DXC") &&
              cellData in this.imageIdUrlMap &&
              !this.imageIdUrlMap[cellData]['hash']) {
                row[colName] = this.imageIdUrlMap[cellData]['src']
              }
            }
          })
        }
      })

      const replaceMacrosResponse = await APIService.replacePromotionMacros(this.promotionId, null);
      let isLocationsValid = this.creativeSpecLocationValidation(this.facebookSpecForPromo, promoObj);
      let storeWithNoLocationMessage = 'Few stores with no creative found. Please deselect the store from the promo or enable creative for the store. Confirm to proceed as it is.';

      if (this.isLivePromotion) {
        let latestPromotionVersion = await APIService.getLatestPromotionVersion(promoObj.id);
        let originalCreativeJson = latestPromotionVersion.creativeJson;
        latestPromotionVersion.creativeJson = JSON.stringify({
          facebook: {
            facebookSpec: JSON.stringify(this.facebookSpecForPromo),
            handsonSpec: JSON.stringify(handsonSpec)
          },
          google: promoObj.creativeJson["google"]
        });

        // checking if any changes made
        if (!this.areJsonObjectsEqual(this.removeUnusedHandsonspec(latestPromotionVersion.creativeJson), this.removeUnusedHandsonspec(originalCreativeJson))) {
          if (latestPromotionVersion.status == promotionVersionEnums.Draft) {
            // CASE: When in edit flow, changes has been made to schedule/budget and new version is already created.
            if (!isLocationsValid) {
              this.stopNavigationToSummaryPage = true;
              this.showConfirmModal({
                content: storeWithNoLocationMessage,
                onOk: async () => {
                  this.stopNavigationToSummaryPage = false;
                  await APIService.patchPromotionVersion(latestPromotionVersion.id, latestPromotionVersion);
                  this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
                  this.nextClickedAfterAdSheetLivePromo(editedRows, selectedConfig); // Navigates to summary page
                },
                onCancel: () => {
                  this.fetchAlwaysCallback();
                }
              });
            } else {
              this.stopNavigationToSummaryPage = false;
              await APIService.patchPromotionVersion(latestPromotionVersion.id, latestPromotionVersion);
            }
          } else {
            // CASE: When changes has been made for the first time and new promotion is not created yet.
            this.stopNavigationToSummaryPage = true;
            this.showConfirmModal({
              content: `Changes detected, this will create a new version. Launch is disabled until you test and enable the promotion. ${!isLocationsValid ? '<br><br>WARNING:<br>' + storeWithNoLocationMessage : 'Confirm to proceed.'}`,
              onOk: async () => {
                this.stopNavigationToSummaryPage = false;
                latestPromotionVersion.status = promotionVersionEnums.Draft;
                // setting these properties to null as they well be set in the post of prmoVersion
                latestPromotionVersion.createdAt = null;
                latestPromotionVersion.updatedAt = null;
                latestPromotionVersion.lastUpdatedByUserId = null;
                latestPromotionVersion.createdByUserId = null;
                await APIService.createPromotionVersion(promoObj.id, latestPromotionVersion);
                this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
                this.nextClickedAfterAdSheetLivePromo(editedRows, selectedConfig); // Navigates to summary page
                EventBus.$emit("PromotionLaunchWizard-enableLaunchButton");
              },
              onCancel: () => {
                this.fetchAlwaysCallback();
              }
            });
          }
        } else {
          this.stopNavigationToSummaryPage = false;
        }
      } else if (promoObj.status != promotionStatus.enabled) {
        if (!isLocationsValid) {
          this.stopNavigationToSummaryPage = true;
          this.showConfirmModal({
            content: storeWithNoLocationMessage,
            onOk: async () => {
              this.stopNavigationToSummaryPage = false;
              await APIService.updatePromotionCreative(this.promotionId, {
                CreativeJson: {
                  facebook: {
                    facebookSpec: JSON.stringify(this.facebookSpecForPromo),
                    handsonSpec: JSON.stringify(handsonSpec)
                  },
                  google: promoObj.creativeJson["google"]
                }
              });
              this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
              this.nextClickedAfterAdSheetLivePromo(editedRows, selectedConfig); // Navigates to summary page
            },
            onCancel: () => {
              this.fetchAlwaysCallback();
            }
          });
        } else {
          this.stopNavigationToSummaryPage = false;
          await APIService.updatePromotionCreative(this.promotionId, {
            CreativeJson: {
              facebook: {
                facebookSpec: JSON.stringify(this.facebookSpecForPromo),
                handsonSpec: JSON.stringify(handsonSpec)
              },
              google: promoObj.creativeJson["google"]
            }
          });
        }
      }

      if (!this.stopNavigationToSummaryPage) {
        this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
      }
    },
    creativeSpecLocationValidation (fbSpec, promoObj) {
      let locations = new Set();
      let creativeWithNoLocation = 0;
      Object.keys(fbSpec).forEach(adFormat => {
        if (Array.isArray(fbSpec[adFormat])) {
          fbSpec[adFormat].forEach(obj => {
            if (obj && obj["AdStatus"] !== "Paused" && obj["locations"]) {
              let locationArray = obj["locations"].split(",");
              locationArray.forEach(location => locations.add(location.trim()));
            } else if (obj && obj["AdStatus"] !== "Paused") {
              creativeWithNoLocation++;
            }
          });
        }
      });

      if ((locations.size < promoObj.enabledStoreIds.length) && creativeWithNoLocation == 0) {
        return false;
      }

      return true;
    },
    replaceMacroAndCommitSpec (promoObj, replaceMacrosResponse) {
      let replaceMacrosObj = replaceMacrosResponse.data.data;
      if (typeof replaceMacrosObj.creativeJson === 'string' && replaceMacrosObj.creativeJson.trim() !== '') {
        replaceMacrosObj.creativeJson = JSON.parse(replaceMacrosObj.creativeJson);
        if (this.selectedPublisher == this.publisher.Facebook) {
          let facebookSpecObj = JSON.parse(replaceMacrosObj.creativeJson.facebook.facebookSpec)
          let facebookSpecArray = Object.values(facebookSpecObj).flat()
          this.$store.commit(
            "set_previewableSpec",
            facebookSpecArray
          );
          this.$store.commit(
            "set_creativesToPublish",
            JSON.parse(replaceMacrosObj.creativeJson.facebook.handsonSpec)
          );
        } else if (this.selectedPublisher == this.publisher.Adwords) {
          let handsonSpecObj = JSON.parse(replaceMacrosObj.creativeJson.google.handsonSpec)
          let handsonSpecArray = Object.values(handsonSpecObj).flat()
          this.$store.commit(
            "set_previewableSpec",
            handsonSpecArray
          );
          this.$store.commit(
            "set_creativesToPublish",
            JSON.parse(replaceMacrosObj.creativeJson.google.googleSpec)
          );
        }
        this.set_promotionObject(promoObj);
      }
    },
    // this method is used to ignore HOT_IsNotPublished and HOT_IsEdited while checking if any changes made
    removeUnusedHandsonspec (creativeJson) {
      var handsonSpec = {};
      if (this.selectedPublisher == this.publisher.Facebook) {
        handsonSpec = JSON.parse(JSON.parse(creativeJson)["facebook"]["handsonSpec"]);
      } else if (this.selectedPublisher == this.publisher.Adwords) {
        handsonSpec = JSON.parse(JSON.parse(creativeJson)["google"]["handsonSpec"]);
      }
      Object.keys(handsonSpec).forEach((format) => {
        if (format !== 'adSheetSettings') {
          handsonSpec[format] = handsonSpec[format].map((obj) => {
            if (this.selectedPublisher == this.publisher.Facebook) {
              obj["HOT_Ad Status"] = obj["HOT_Ad Status"] === null || obj["HOT_Ad Status"] === undefined ? 'Active' : obj["HOT_Ad Status"];
            }
            delete obj["HOT_IsNotPublished"];
            delete obj["HOT_isEdited"];
            return obj;
          });
        }
      });
      return JSON.stringify(handsonSpec);
    },
    areJsonObjectsEqual (json1, json2) {
      const obj1 = JSON.parse(json1);
      const obj2 = JSON.parse(json2);

      // Deep comparison of objects. This is used to check individual ad object across adFormats like single, carousel..
      // It is necessary since object properties order could differ,
      function deepEqual (a, b) {
        if (a === b) return true;

        if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
          return false;
        }

        const keysA = Object.keys(a);
        const keysB = Object.keys(b);

        if (keysA.length !== keysB.length) return false;

        for (let key of keysA) {
          if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
            return false;
          }
        }

        return true;
      }

      let returnValue = true;

      // Using some since we need to break the loop if misMatch and forEach doesn't support break keyword.
      Object.keys(obj1).some((format) => {
        if (format !== 'adSheetSettings') {
          if (obj1[format].length !== obj2[format].length) {
            returnValue = false;
            return true;
          }
          obj1[format].some((obj, index) => {
            if (!deepEqual(obj, obj2[format][index])) {
              returnValue = false;
              return true;
            }
            return false;
          });

          if (!returnValue) {
            return true;
          }
          return false;
        }
      });

      return returnValue;
    },
    initCampaignConfigToTestPromo (config) {
      config = JSON.parse(config)
      // Setting campaign status to paused while testing promotions
      config.campaign.status = "2"
      // Appending time to campaign name so we can relaunch published campaigns
      config.campaign.name += ` ${moment().format("(DD-MM-YYYY hh:mm:ss A)")}`
      return JSON.stringify(config)
    },
    updateRouteToReview () {
      this.$router.replace({ path: "/review-summary", query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: this.selectedCampaignLauncherConfigId, view: this.$route.query.view } })
    },
    updateRouteToSummary () {
      if (this.$route.query.view && this.$route.query.view == 'Full') {
        this.$router.replace({ path: "/summary", query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: this.selectedCampaignLauncherConfigId, view: 'Full' } })
      } else {
        this.$router.replace({ path: "/summary", query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: this.selectedCampaignLauncherConfigId } })
      }
      this.set_autoNavigateToNextStep(false);
    },
    async ShowNextStep () {
      if (this.selectedPublisher == this.publisher.Adwords) {
        this.showAdwordsNextStep();
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        this.SaveAmsSpTableData = true;
        EventBus.$emit("SaveAmazonSpTargets", this.SaveAmsSpTableData);
        this.showAMSSpNextStep();
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredBrands) {
        this.SaveAmsTableData = true;
        EventBus.$emit("SaveAmazonTargets", this.SaveAmsTableData);
        this.showAMSNextStep();
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        this.SaveAmsSDTableData = true;
        EventBus.$emit("SaveAmazonSDTargets", this.SaveAmsSDTableData);
        this.showAMSNextStep();
      } else if (this.selectedPublisher == this.publisher.DV360) {
        this.showDV360NextStep();
      } else if (this.selectedPublisher == this.publisher.LinkedIn) {
        this.showLinkedInNextStep();
      } else if (this.selectedPublisher == this.publisher.Twitter) {
        this.showTwitterNextStep();
      } else if (this.selectedPublisher == this.publisher.Moloco) {
        this.showMolocoNextStep();
      } else {
        this.$store.commit("set_isCreationFlow", true);
        var editedRows = []
        let selectedConfig = this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
        if (this.isPromotionMode) {
          this.getPreviewableRows();
          await this.processPromotion(editedRows, selectedConfig);
          selectedConfig = this.initCampaignConfigToTestPromo(selectedConfig)
        } else if (!this.autoNavigateToNextStep) {
          this.getPreviewableRows();
          editedRows = this.getEditedRows();
        }
        if (!this.stopNavigationToSummaryPage) {
          this.nextClickedAfterAdSheetLivePromo(editedRows, selectedConfig);
        }
      }
    },
    async nextClickedAfterAdSheetLivePromo (editedRows, selectedConfig) {
      this.SaveCreative(true, this.autoNavigateToNextStep);
      if (!this.isPromotionMode && this.autoNavigateToNextStep) {
        while (this.tableData == null) {
          console.log("waiting for hot wrapper...")
          await this.sleep(1000);
        }
        this.getCreativeStructure();
        editedRows = this.getEditedRows();
      }
      var requestData = {
        config: JSON.stringify({
          config: selectedConfig,
          lineItemConfig: this.mappedLineItems
        }),
        creativeSpec: JSON.stringify(this.previewableSpec),
        mediaPlanId: this.mediaPlanId,
        configId: this.selectedCampaignLauncherConfigId,
        isPromotionCampaignLaunch: this.isPromotionMode,
        editedSpec: JSON.stringify(editedRows ? editedRows.specs : [])
      };
      if (this.deltax.isDeltaxApiFlowEnabled || this.isPromotionMode) {
        if (this.mappedLineItems && this.mappedLineItems.length > 0) {
          this.mappedLineItems.forEach(li => (li.labels = this.labelsToBeApplied));
        }
        APIService.PostCampaignLauncherRequestData(
          this.publishStageDataApiUrl,
          {
            configId: this.selectedCampaignLauncherConfigId,
            mediaPlanId: this.mediaPlanId,
            promotionId: this.promotionId,
            isPromotionCampaignLaunch: this.isPromotionMode,
            creativeData: JSON.stringify(this.previewableSpec),
            lineItemConfig: JSON.stringify(this.mappedLineItems),
            editedSpec: JSON.stringify(editedRows ? editedRows.specs : [])
          }
        ).then(response => {
          let data = response.data;
          data.facebookEntities = JSON.parse(data.childEntitiesJson);
          data.facebookEntities["requestId"] = data.requestId;
          data.facebookEntities["redisKey"] = data.redisKey;
          data.facebookEntities["allMacroReplaced"] = data.allMacroReplaced;
          data.facebookEntities["publishedEditedSummaryData"] = data.publishedEditedSummaryData;
          this.$store.commit("SET_FACEBOOK_ENTITIES", data.facebookEntities);
          this.$store.commit("SET_SHOW_PREVIEW", true);
          if (!this.isPromotionMode) {
            this.updateRouteToSummary();
          }
          EventBus.$emit("adSheetWidget-creativesSaved", true);
        }).catch((response) => {
          this.fetchErrorCallback(response)
          EventBus.$emit("adSheetWidget-creativesSaved", false);
        }).finally(this.fetchAlwaysCallback);
      } else {
        APIService.getGroupEntities(
          this.fetchGroupedEntitiesURL,
          requestData,
          this.fetchSuccessCallback,
          this.fetchErrorCallback,
          this.fetchAlwaysCallback
        );
      }
    },
    async saveAndGenerateEntities () {
      let requestData = {
        configId: this.selectedCampaignLauncherConfigId,
        mediaPlanId: this.mediaPlanId,
        isNewFlow: this.deltax.isAdwordsCampaignLaucherFlow,
        isPromotionCampaignLaunch: this.isWizardFlow,
        promotionId: this.promotionId,
        lineItemConfig: JSON.stringify(this.mappedLineItems)
      };
      requestData["childEntitiesConfiguration"] = this.creativesToPublish;
      this.SaveCreative(true, this.autoNavigateToNextStep);
      // get editable rows
      this.getEditedPreviewableRows();
      requestData["editedChildEntitiesConfiguration"] = this.editedCreativesToPublish;
      var url = this.deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/PublishStageData"
      APIService.PostCampaignLauncherRequestData(
        url,
        requestData,
        true
      ).then(response => {
        this.adWordsSuccessCallback(response)
        EventBus.$emit("adSheetWidget-creativesSaved", true);
      }).catch((response) => {
        this.fetchErrorCallback(response)
        EventBus.$emit("adSheetWidget-creativesSaved", false);
      }).finally(this.fetchAlwaysCallback);
    },
    async SwitchConfigFromSummary (forceDataload) {
      if (this.selectedPublisher == this.publisher.Facebook && !this.isPromotionMode) {
        this.$store.commit("set_isCreationFlow", true);

        let selectedConfig = this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config

        if (forceDataload) {
          while (this.tableData == null) {
            console.log("waiting for hot wrapper...")
            await this.sleep(1000);
          }
          this.getCreativeStructure();
        }
        var requestData = {
          config: JSON.stringify({
            config: selectedConfig,
            lineItemConfig: this.mappedLineItems
          }),
          creativeSpec: forceDataload ? JSON.stringify(this.previewableSpec) : "[]",
          mediaPlanId: this.mediaPlanId,
          configId: this.selectedCampaignLauncherConfigId,
          isPromotionCampaignLaunch: this.isPromotionMode
        };
        APIService.getGroupEntities(
          this.fetchGroupedEntitiesURL,
          requestData,
          this.fetchSuccessCallback,
          this.fetchErrorCallback,
          this.fetchAlwaysCallback
        );
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    SaveCreative (autoSave = false, autoNavigateToNextStep = false) {
      var data = "";
      var editTabData = "";
      if (this.selectedPublisher == this.publisher.AMS.SponsoredDisplay || this.selectedPublisher == this.publisher.AMS.SponsoredProducts) {
        this.selectedPublisher == this.publisher.AMS.SponsoredProducts ? EventBus.$emit("saveAmazonSpCreative", true) : EventBus.$emit("saveAmazonSdCreative", true);
        if (this.AmsDataValid) {
          data = JSON.stringify(this.amsSheetDetails);
          this.saveCreativeRequest(autoSave, autoNavigateToNextStep, this.publisher.AMS.id, data)
        } else {
          var message = "Please fill in the Adgroup or Keyword or ASIN targeting information to proceed.";
          this.$Message.error({ background: true, content: message });
          this.saveAlwaysCallback();
        }
      } else {
        this.getEditedRows()
        data = JSON.stringify(this.getAllRows());
        editTabData = JSON.stringify(this.getEditTabRows());
        this.saveCreativeRequest(autoSave, autoNavigateToNextStep, this.selectedPublisher, data, editTabData)
      }
      if (this.isPromotionMode) {

      }
    },
    saveCreativeRequest (autoSave, autoNavigateToNextStep, publisherId, data, editTabData) {
      var requestData = {
        creativeJson: data,
        mediaPlanId: this.mediaPlanId,
        configId: this.selectedCampaignLauncherConfigId,
        isUpdate: true,
        editedCreativeJson: JSON.stringify(this.$store.state.publishedCreativesInitialState),
        SaveSheet: !autoNavigateToNextStep,
        propertyList: (this.selectedPublisher != this.publisher.Adwords) ? this.adFormats : this.adwordsAdFormats,
        publisherId: publisherId
      };
      if (autoSave) {
        requestData.autoSave = true
      }
      var saveCreativePromise
      if (this.selectedPublisher == this.publisher.Adwords) {
        requestData.editedCreativeJson = editTabData;
      }
      if (this.publishersSupportingNewLauncherFlow.includes(publisherId)) {
        requestData.editedCreativeJson = editTabData;
        requestData.version = this.currentVersionOfGeneratedEntities;
        delete requestData.isUpdate;
        delete requestData.propertyList;
        saveCreativePromise = APIService.saveNewLauncherFlowCreatives(requestData)
      } else {
        saveCreativePromise = APIService.saveCreative(requestData)
      }
      saveCreativePromise
        .then(response => {
          this.saveSuccessCallback(response, autoSave)
          if (!autoSave && this.saveAlwaysCallback) {
            this.saveAlwaysCallback()
          }
        })
        .catch((error) => {
          this.saveErrorCallback(error)
          if (!autoSave) {
            this.saveAlwaysCallback()
          }
        });
    },
    saveSuccessCallback (response, showNextStep) {
      if (this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId]) {
        this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId].CreativeUpdatedAt = (new Date()).toLocaleString()
      } else {
        this.creativeSavedAt = (new Date()).toLocaleString();
      }
      if (response.data.currentVersion) {
        var version = response.data.currentVersion
        this.set_currentVersionOfGeneratedEntities(version)
      } else {
        this.set_currentVersionOfGeneratedEntities(1)
      } if (this.selectedPublisher == this.publisher.Moloco && showNextStep) {
        this.getCampaignLauncherPublishDataId()
      }
      if (this.selectedPublisher == this.publisher.Moloco && !showNextStep) {
        this.adSheetMode = 'Create'
        this.adSheetModeChange('Create')
        this.getAdsheetData()
        this.editDatafetchedForConfigId = null
      }
    },
    fetchSuccessCallback (response) {
      var data = JSON.parse(response);
      if (data.error) {
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Labels mapped for lineItem <strong>${data.error}</strong> has no targets. Please add targets for the selected labels or select another label.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        data.facebookEntities["requestId"] = data.requestId;
        data.facebookEntities["allMacroReplaced"] = data.allMacroReplaced;
        data.facebookEntities["publishedEditedSummaryData"] = data.publishedEditedSummaryData;
        this.$store.commit("SET_FACEBOOK_ENTITIES", data.facebookEntities);
        this.$store.commit("SET_SHOW_PREVIEW", true);
        if (!this.isPromotionMode) {
          this.updateRouteToSummary();
        }
      }
    },
    adWordsSuccessCallback (response) {
      // var data = JSON.parse(response);
      var data = response.data
      if (data.error) {
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Labels mapped for lineItem <strong>${data.error}</strong> has no targets. Please add targets for the selected labels or select another label.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        if (this.deltax.isAdwordsCampaignLaucherFlow) {
          var targetsToFetch = []
          var locationsToFetch = []
          data.campaigns.forEach(c => {
            targetsToFetch = targetsToFetch.concat(c.targetId)
            if (this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign.locationMode == adwordsEnums.locationMode.Standard) {
              if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Demand Gen']) {
                c.adgroups.forEach(adgrp => {
                  locationsToFetch = locationsToFetch.concat(adgrp.targeting.locations.included.locationCodes)
                  locationsToFetch = locationsToFetch.concat(adgrp.targeting.locations.excluded.locationCodes)
                });
              } else {
                locationsToFetch = locationsToFetch.concat(c.targeting.locations.included.locationCodes)
                locationsToFetch = locationsToFetch.concat(c.targeting.locations.excluded.locationCodes)
              }
            }
          })
          var apiRequests = [APIService.getDbLocations({ googleCodes: locationsToFetch.join(",") }),
            APIService.getTargets(targetsToFetch.join(","))]
          Promise.all(apiRequests).then((values) => {
            this.setAdwordsLocations(values[0])
            this.setAdwordsTargets(values[1])
            this.$store.commit("set_adwordsEntitiesResponse", data);
            this.$store.commit("SET_SHOW_PREVIEW", true);
            if (!this.isPromotionMode) {
              this.updateRouteToSummary();
            }
          }).catch(e => {
            this.fetchErrorCallback(e)
          })
        } else {
          this.$store.commit("set_adwordsEntitiesResponse", data);
          this.$store.commit("SET_SHOW_PREVIEW", true)
          this.updateRouteToSummary();
        }
      }
    },
    amsSuccessCallback (response) {
      var data = response;
      var isValidRequestIdPresent = null
      if (data != null && data.data != null && data.data.requestId != null) {
        isValidRequestIdPresent = data.data.requestId > 0
      }
      if (data.error) {
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Labels mapped for lineItem <strong>${data.error}</strong> has no targets. Please add targets for the selected labels or select another label.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else if (!isValidRequestIdPresent && isValidRequestIdPresent != null) {
        // We know something failed so need to know what failed and raise Error.
        this.verifyAmsPublishStatus(data.data.amsEntities)
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Something went wrong while processing your request. Reason: <strong>${this.failureReason}</strong> `
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        this.set_amsEntitiesResponse(data)
        this.$store.commit("SET_SHOW_PREVIEW", true);
        this.updateRouteToSummary();
      }
    },
    DV360SuccessCallback (response) {
      var data = response;
      var isValidRequestIdPresent = null
      if (data != null && data.data != null && data.data.requestId != null) {
        isValidRequestIdPresent = data.data.requestId > 0
      }
      if (data.error) {
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Labels mapped for lineItem <strong>${data.error}</strong> has no targets. Please add targets for the selected labels or select another label.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else if (!isValidRequestIdPresent && isValidRequestIdPresent != null) {
        // We know something failed so need to know what failed and raise Error.
        // this.verifyDbmPublishStatus(data.data.dbmEntities)
        this.$store.commit("set_showCampaignLauncherAlert", true);
        this.$store.commit(
          "set_campaignLauncherAlertMessage",
          `Something went wrong while processing your request. Reason: <strong>${this.failureReason}</strong> `
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        this.set_dv360EntitiesResponse(data);
        this.$store.commit("SET_SHOW_PREVIEW", true);
        this.updateRouteToSummary();
      }
    },
    LinkedInSuccessCallback (response) {
      var data = response;
      if (data.errorCode && data.message) {
        // We know something failed so need to know what failed and raise Error.
        this.$store.commit("set_showCampaignLauncherError", true);
        this.$store.commit(
          "set_campaignLauncherErrorMessage",
          `Something went wrong while processing your request.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        this.set_linkedInEntitiesResponse(data);
        this.$store.commit("SET_SHOW_PREVIEW", true);
        this.updateRouteToSummary();
      }
    },
    twitterSuccessCallback (response) {
      var data = response;
      if (data.errorCode && data.message) {
        // We know something failed so need to know what failed and raise Error.
        this.$store.commit("set_showCampaignLauncherError", true);
        this.$store.commit(
          "set_campaignLauncherErrorMessage",
          `Something went wrong while processing your request.`
        );
        this.$store.commit("set_isCreationFlow", false);
      } else {
        this.set_twitterEntitiesResponse(data);
        this.$store.commit("SET_SHOW_PREVIEW", true);
        this.updateRouteToSummary();
      }
    },
    fetchErrorCallback (response) {
      if (this.isAssetClaimRequiredException(response)) {
        this.$Message.error({
          background: true,
          content: this.exceptionLabels.find(x => x.family === "assetClaimRequiredException").label
        });
      } else if (this.selectedPublisher == this.publisher.Adwords) {
        this.$store.commit("set_showCampaignLauncherError", true);
        this.$store.commit("set_campaignLauncherErrorMessage", response.message);
      } else {
        this.$Message.error({
          background: true,
          content: response.message
        });
      }
      this.$store.commit("set_isCreationFlow", false);
    },
    saveErrorCallback (error) {
      this.$Message.error({
        background: true,
        content: error && error.response && error.response.data && error.response.data.errorCode == 115 ? error.response.data.message : "An error occurred while processing your request."
      });
    },
    verifyAmsPublishStatus (childEntities) {
      childEntities.forEach(element => {
        if (element.status === 4 && element.failureReason != "") {
          this.failureReason = element.failureReason
        } else if (element["childEntities"]) {
          return this.verifyAmsPublishStatus(element.childEntities)
        }
      });
    },
    isAssetClaimRequiredException (response) {
      return this.selectedPublisher == this.publisher.Facebook && response.responseText && response.responseText.includes("AssetsClaimRequiredException")
    },
    adFormatNeedsToBeDisabled (adFormat) {
      if (this.selectedPublisher === this.publisher.LinkedIn) {
        if (this.selectedCampaignLauncherConfig.placement && adFormat === 'text') {
          return true
        } else if (adFormat === 'text' && this.selectedCampaignLauncherConfig.biddingStrategy != 3) {
          return true
        }
      }
      return false
    },
    saveAdSheetSettings (value, adFormat) {
      if (!value) { return; }
      if (this.selectedPublisher === this.publisher.Adwords) {
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Demand Gen']) {
          this.adwordsAdSheetSettings = value.adSheetSettings;
          this.customizedAdWordsColHeaders = [];
          let adFormatsForGen = this.adwordsAdFormats;
          for (let demandGenAdFormat in adFormatsForGen) {
            let name = adFormatsForGen[demandGenAdFormat]
            this.customizedAdWordsColHeaders = value.customizedColHeaders[name] || [];
            [this.colHeaders[name], this.displayColHeaders[name]] = this.buildColHeaders(name);
          }
          this.SaveCreative();
          let rowData = this.getAllRows();
          this.set_publishedCreatives(rowData)
          this.set_createSheetCreatives(rowData)
        } else {
          this.adwordsAdSheetSettings = value.adSheetSettings;
          this.customizedAdWordsColHeaders = [];
          this.customizedAdWordsColHeaders = value.customizedColHeaders;
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat);
          this.SaveCreative();
          let rowData = this.getAllRows();
          this.set_publishedCreatives(rowData)
          this.set_createSheetCreatives(rowData)
          // for pmax campaigns get edit tab data when adsheet settings applied
          if (this.isAdwordsPerformanceMaxCampaign) {
            // get all rows from edit tab instead of edited only
            let editRowData = this.getEditTabRows(false);
            let publishRowData = this.getPublishTabRows();
            this.set_editSheetCreatives(editRowData);
            this.set_publishedSheetCreatives(publishRowData);
          }
        }
      } else {
        if (value.type == "placementCustomization") {
          this.extraPlacementCustomizationHeaders = value.placementHeaders;
          this.optionalColumns = value.beakerPlacementCustomizationHeaders
          var pcHeader = [this.placementCustomizationHeaders[adFormat][0]]
          pcHeader.push(...value.beakerPlacementCustomizationHeaders.map(x => x))
          this.placementCustomizationHeadersLocalVar[adFormat] = pcHeader
          this.placementCustomizationSettings = value.placementCustomizationSettings;
        } else if (value.type == "dpaCreativeTool") {
          this.dpaCreativeToolHeaders = value.dpaCreativeToolHeaders;
          this.dpaCreativeToolSettings = value.dpaCreativeToolSettings;
        } else if (value.type == "languageCustomization") {
          this.languageCustomizationHeaders = this.languageCustomizationHeaders || {};
          this.languageCustomizationHeaders[adFormat] = value.languageHeaders;
          this.languageCustomizationSettings = value.languageCustomizationSettings;
        } else if (value.type == "dynamicCreative") {
          this.dynamicCreativeHeaders = value.dynamicCreativeHeaders
          this.dynamicCreativeSettings = value.dynamicCreativeSettings;
        }

        if (Array.isArray(value.selectedLabelDimensions)) {
          this.adLabelDimensionSettings = value.selectedLabelDimensions;
        }

        if (value.saveRequired) {
          this.SaveCreative();
          var data = this.getAllRows();
          this.updateCreativeSetupData(data);
          this.set_publishedCreatives({})
          this.set_publishedCreatives(data)
          this.set_createSheetCreatives(data)
          if (this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId]) {
            this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId].creativeJson =
            JSON.stringify(data);
          } else {
            this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId] = {
              creativeJson: JSON.stringify(data)
            }
          }
        }
        if (value.type == "dynamicCreative" || value.type == "placementCustomization") {
          this.$nextTick(() => {
            this.rerendererKey ^= 1;
          })
        } else {
          this.skipResetSelectedTab = true;
          this.set_showAdSheetLoader(true);
          setTimeout(() => {
            this.set_showAdSheetLoader(false);
          }
          );
        }
      }
    },
    // mergeExtraColumnsInPlacementCustomization (pcHeader, placementCustomizationConfigHeaders) {
    //   if (pcHeader && placementCustomizationConfigHeaders) {
    //     var existingColumns = placementCustomizationConfigHeaders
    //     pcHeader.forEach(option => {
    //       if (!existingColumns.includes(option)) {
    //         var similarValue = option.split(" - ")
    //         if (similarValue[0]) {
    //           var position = existingColumns.indexOf(similarValue[0])
    //           if (position < 0) {
    //             existingColumns.push(option)
    //           }
    //           existingColumns.splice(position + 1, 0, option)
    //         }
    //       }
    //     })
    //   }
    //   return existingColumns
    // },
    getTargetsMappedToConfig () {
      let mappedTargetIds = [];
      this.mediaPlanLineItems.forEach(element => {
        if (element.config === this.selectedCampaignLauncherConfigId) {
          mappedTargetIds.push(element.targets);
        }
      });
      mappedTargetIds = new Set(mappedTargetIds.flat());
      let targets = this.savedTargets.filter(x =>
        x.businessProfilesSearchEngineId == this.businessProfilesSearchEngineId
      );
      let targetsMapped = targets.filter(target =>
        mappedTargetIds.has(target.id)
      );
      return targetsMapped;
    },
    showDV360AdFormatCheck (adFormat) {
      if (adFormat == "image") {
        return this.targetsMappedToConfig.some(x => x.type == 1 || x.type == 0)
      } else if (adFormat == "youtube") {
        return this.targetsMappedToConfig.some(x => x.type == 4)
      }
    },
    changeTab (tabName) {
      this.skipResetSelectedTab = true;
      this.selectedTab = tabName;
    },
    updateCreativeInPreview (pageNumber) {
      this.creativeInPreview = pageNumber;
    },
    async fetchExistingPosts (existingPostIds) {
      if (!this.isPostsFetched) {
        const response = await APIService.getPublishedPosts(this.businessProfileId, this.businessProfilesSearchEngineId, this.selectedCampaignLauncherConfig.facebookPageId);
        const existingPosts = JSON.parse(response);

        if (existingPosts.connectedInstagramAccountId) this.set_connectedInstagramAccountId(existingPosts.connectedInstagramAccountId);
        if (Array.isArray(existingPosts.facebookPosts) && !("error" in existingPosts.facebookPosts)) this.set_facebookPosts(existingPosts.facebookPosts);
        if (Array.isArray(existingPosts.instagramPosts) && !("error" in existingPosts.instagramPosts)) this.set_instagramPosts(existingPosts.instagramPosts);

        this.set_isPostsFetched(true);
      }

      if (!Array.isArray(existingPostIds)) return;

      let postIds = existingPostIds
        .map(e => e["HOT_Post ID"])
        .filter(e => e && !this.facebookPosts.map(e => e.id).includes(e) && !this.instagramPosts.map(e => e.id).includes(e));

      if (!postIds || postIds.length == 0) return;

      postIds = [...new Set(postIds)];

      const posts = await APIService.getPostsByIds(this.businessProfileId, this.businessProfilesSearchEngineId, postIds);
      const facebookPosts = posts.data.data.filter(e => !e.ig_id);
      const instagramPosts = posts.data.data.filter(e => e.ig_id);

      this.set_facebookPosts([...this.facebookPosts, ...facebookPosts]);
      this.set_instagramPosts([...this.instagramPosts, ...instagramPosts]);
    },
    getAdsheetData () {
      if (this.selectedPublisher == this.publisher.Facebook) {
        if (this.adSheetMode == 'Create') {
          this.set_showAdSheetLoader(true);
          this.isCreateSheetDataCallCompletedForFacebook = false
          APIService.getAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Facebook)
            .then(async res => {
              var response = res.data.data
              if (response && response.publishedCreativesInfo) {
                this.$store.commit("set_allCreativesInfo", response.publishedCreativesInfo)
              }
              if (response && response.adSheetCreatives && response.adSheetCreatives.length) {
                this.$store.commit("set_createSheetCreatives", response.adSheetCreatives[0]);
                EventBus.$emit("adsheetWidget-configSwitched");
                if (response.adSheetCreatives[0] && response.adSheetCreatives[0].adSheetSettings) {
                  this.set_adSheetCustomizationSettings(response.adSheetCreatives[0].adSheetSettings);
                }

                if (response.adSheetCreatives[0] && response.adSheetCreatives[0]["existing posts"]) {
                  await this.fetchExistingPosts(response.adSheetCreatives[0]["existing posts"]);
                }
              }
              // if (creativeJsonObj["adSheetSettings"]) {
              //   this.set_adSheetCustomizationSettings(creativeJsonObj["adSheetSettings"]);
              // }
              let publishedCreativesByCampaignConfig = {};
              publishedCreativesByCampaignConfig[this.selectedCampaignLauncherConfigId] = {
                creativeJson: response.adSheetCreatives[0],
                CreativeUpdatedAt: response.creativeUpdatedAt ? (new Date(response.creativeUpdatedAt)).addMinutes(-(new Date(response.creativeUpdatedAt)).getTimezoneOffset()).toLocaleString() : 'No saved data',
                editedCreativeJson: {}
              };
              this.set_publishedCreativesByCampaignConfigId(
                publishedCreativesByCampaignConfig
              );
              this.setCreativesInitialState();
              this.set_showAdSheetLoader(false);
              this.isCreateSheetDataCallCompletedForFacebook = true
            })
            .catch((exception) => {
              // We know something failed so need to know what failed and raise Error.
              this.$store.commit("set_showCampaignLauncherError", true);
              this.$store.commit(
                "set_campaignLauncherErrorMessage",
                `Unable to fetch Create sheet data.`
              );
              console.log("Unable to fetch Create sheet data.", exception)
            });
        } else {
          if (!this.isEditDataFetchComplete || (this.publishedDatafetchedForConfigId != this.selectedCampaignLauncherConfigId && !this.isPromotionMode)) {
            APIService.getAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Facebook)
              .then(async res => {
                var response = res.data.data
                if (response && response.publishedCreativesInfo) {
                  this.$store.commit("set_allCreativesInfo", response.publishedCreativesInfo)
                }
                if (response && response.adSheetCreatives && response.adSheetCreatives.length) {
                  this.$store.commit("set_publishedSheetCreatives", response.adSheetCreatives[0])
                  EventBus.$emit("adsheetWidget-publishedCreativesFetched")

                  if (response.adSheetCreatives[0] && response.adSheetCreatives[0]["existing posts"]) {
                    await this.fetchExistingPosts(response.adSheetCreatives[0]["existing posts"]);
                  }
                }

                if (response && response.editedCreativeJson && response.editedCreativeJson.length && response.editedCreativeJson[0] && response.editedCreativeJson[0]["existing posts"]) {
                  await this.fetchExistingPosts(response.editedCreativeJson[0]["existing posts"]);
                }

                if (response && response.adCreativeMapping && response.adCreativeMapping.length) {
                  this.$store.commit("set_adCreativeMapping", response.adCreativeMapping)
                }
                let publishedCreativesByCampaignConfig = {};
                var allCreatives = {};
                if (response.adSheetCreatives.length) {
                  for (var key of Object.keys(response.adSheetCreatives[0])) {
                    if (key == "adSheetSettings") {
                      allCreatives[key] = {}
                      if (response.adSheetCreatives[0][key]["adLabelDimensions"]) {
                        var adLabelDimensions = response.adSheetCreatives[0][key]["adLabelDimensions"].concat(this.createSheetCreatives[key]["adLabelDimensions"])
                        allCreatives[key]["adLabelDimensions"] = [...new Set(adLabelDimensions)]
                      }
                    } else {
                      allCreatives[key] = response.adSheetCreatives[0][key].concat(this.createSheetCreatives[key])
                    }
                  }
                }
                publishedCreativesByCampaignConfig[this.selectedCampaignLauncherConfigId] = {
                  creativeJson: allCreatives,
                  CreativeUpdatedAt: response.creativeUpdatedAt ? (new Date(response.creativeUpdatedAt)).addMinutes(-(new Date(response.creativeUpdatedAt)).getTimezoneOffset()).toLocaleString() : 'No saved data',
                  editedCreativeJson: response.editedCreativeJson && response.editedCreativeJson[0] ? response.editedCreativeJson[0] : {}
                };
                this.set_publishedCreativesByCampaignConfigId(
                  publishedCreativesByCampaignConfig
                );
                this.isEditDataFetchComplete = true;
                this.publishedDatafetchedForConfigId = this.selectedCampaignLauncherConfigId
                this.setCreativesInitialState();
              })
              .catch((exception) => {
                // We know something failed so need to know what failed and raise Error.
                this.$store.commit("set_showCampaignLauncherError", true);
                this.$store.commit(
                  "set_campaignLauncherErrorMessage",
                  `Unable to fetch Published sheet data.`
                );
                console.log("Unable to fetch Published sheet data.", exception)
              });
          }
        }
      }
      if (this.selectedPublisher == this.publisher.Moloco) {
        if (this.adSheetMode == 'Create') {
          APIService.getCampaignLauncherAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Moloco)
            .then(res => {
              var response = res.data.data
              if (response && response.sheetInfo) {
                this.$store.commit("set_allCreativesInfo", response.sheetInfo)
              }
              if (response && response.adSheetData) {
                this.$store.commit("set_createSheetCreatives", response.adSheetData);
                EventBus.$emit("adsheetWidget-configSwitched");
              }
              if (response) {
                this.$store.commit("set_currentVersionOfGeneratedEntities", response.version);
              }
              this.publishedDatafetchedForConfigId = 0;
              this.editDatafetchedForConfigId = 0;
            })
            .catch((exception) => {
              // We know something failed so need to know what failed and raise Error.
              this.$store.commit("set_showCampaignLauncherError", true);
              this.$store.commit(
                "set_campaignLauncherErrorMessage",
                `Unable to fetch Create sheet data.`
              );
              console.log("Unable to fetch Create sheet data.", exception)
            });
          this.setEditCreativesInitialState();
        } else if (this.adSheetMode == 'Published') {
          if (this.publishedDatafetchedForConfigId != this.selectedCampaignLauncherConfigId) {
            this.isDataFetchComplete = false;
            APIService.getCampaignLauncherAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Moloco)
              .then(res => {
                var response = res.data.data
                if (response && response.sheetInfo) {
                  this.$store.commit("set_allCreativesInfo", response.sheetInfo)
                }
                if (response && response.adSheetData) {
                  let reversedAdSheetData = {};
                  for (let key in response.adSheetData) {
                    if (Array.isArray(response.adSheetData[key])) {
                      reversedAdSheetData[key] = response.adSheetData[key].slice().reverse();
                    }
                  }
                  this.$store.commit("set_publishedSheetCreatives", reversedAdSheetData)
                  EventBus.$emit("adsheetWidget-publishedCreativesFetched")
                }
                this.publishedDatafetchedForConfigId = this.selectedCampaignLauncherConfigId
                this.isDataFetchComplete = true;
              })
              .catch((exception) => {
              // We know something failed so need to know what failed and raise Error.
                this.$store.commit("set_showCampaignLauncherError", true);
                this.$store.commit(
                  "set_campaignLauncherErrorMessage",
                  `Unable to fetch Published sheet data.`
                );
                console.log("Unable to fetch Published sheet data.", exception)
              });
          }
        } else if (this.adSheetMode == 'Edit') {
          if (this.editDatafetchedForConfigId != this.selectedCampaignLauncherConfigId) {
            this.isEditTabDataFetchComplete = false;
            APIService.getCampaignLauncherEditTabAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId)
              .then(res => {
                var response = res.data.data
                // if (response && response.sheetInfo) {
                //   this.$store.commit("set_allCreativesInfo", response.sheetInfo)
                // }
                if (response) {
                  this.$store.commit("set_editSheetCreatives", response)
                }
                // let publishedCreativesByCampaignConfig = {};
                // var allCreatives = {};
                // publishedCreativesByCampaignConfig[this.selectedCampaignLauncherConfigId] = {
                //   creativeJson: allCreatives,
                //   CreativeUpdatedAt: response.creativeUpdatedAt ? (new Date(response.creativeUpdatedAt)).addMinutes(-(new Date(response.creativeUpdatedAt)).getTimezoneOffset()).toLocaleString() : 'No saved data',
                //   editedCreativeJson: response.editedCreativeJson && response.editedCreativeJson[0] ? response.editedCreativeJson[0] : {}
                // };
                // this.set_publishedCreativesByCampaignConfigId(
                //   publishedCreativesByCampaignConfig
                // );
                this.editDatafetchedForConfigId = this.selectedCampaignLauncherConfigId
                this.isEditTabDataFetchComplete = true;
                // this.setCreativesInitialState();
              })
              .catch((exception) => {
              // We know something failed so need to know what failed and raise Error.
                this.$store.commit("set_showCampaignLauncherError", true);
                this.$store.commit(
                  "set_campaignLauncherErrorMessage",
                  `Unable to fetch Edit sheet data.`
                );
                console.log("Unable to fetch Edit sheet data.", exception)
              });
          }
        }
      }
      if (this.selectedPublisher == this.publisher.Adwords) {
        if (this.adSheetMode == 'Edit') {
          if (this.editDatafetchedForConfigId != this.selectedCampaignLauncherConfigId || this.$store.state.fetchAdwordsEditTab) {
            this.isEditTabDataFetchComplete = false;
            APIService.getOldFlowEditTabData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.publisher.Adwords)
              .then(res => {
                var response = res.data.data;
                if (response) {
                  this.$store.commit("set_editSheetCreatives", response);
                }
                this.editDatafetchedForConfigId = this.selectedCampaignLauncherConfigId;
                this.isEditTabDataFetchComplete = true;
                this.$store.state.fetchAdwordsEditTab = false;
              })
              .catch((exception) => {
                this.$store.commit("set_showCampaignLauncherError", true);
                this.$store.commit(
                  "set_campaignLauncherErrorMessage",
                  `Unable to fetch Edit sheet data.`
                );
                console.log("Unable to fetch Edit sheet data.", exception)
              });
          }
        } else if (this.adSheetMode == 'Published') {
          if (this.publishedDatafetchedForConfigId != this.selectedCampaignLauncherConfigId || this.$store.state.fetchAdwordsPublishTab) {
            this.isDataFetchComplete = false;
            APIService.getCampaignLauncherAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Adwords)
              .then(res => {
                var response = res.data.data
                if (response && response.adSheetCreatives && response.adSheetCreatives.length) {
                  this.$store.commit("set_publishedSheetCreatives", response.adSheetCreatives[0])
                } else {
                  this.$store.commit("set_publishedSheetCreatives", {})
                }
                this.publishedDatafetchedForConfigId = this.selectedCampaignLauncherConfigId
                this.isDataFetchComplete = true;
                this.$store.state.fetchAdwordsPublishTab = false;
              })
              .catch((exception) => {
                // We know something failed so need to know what failed and raise Error.
                this.$store.commit("set_showCampaignLauncherError", true);
                this.$store.commit(
                  "set_campaignLauncherErrorMessage",
                  `Unable to fetch Published sheet data.`
                );
                console.log("Unable to fetch Published sheet data.", exception)
              });
          }
        } else if (this.adSheetMode == 'Create') {
          this.isCreateSheetDataCallCompletedForAdwords = false;
          APIService.getCampaignLauncherAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, this.publisher.Adwords)
            .then(res => {
              var response = res.data.data
              if (response && response.publishedCreativesInfo) {
                this.$store.commit("set_allCreativesInfo", response.publishedCreativesInfo)
              }
              if (response && response.adSheetCreatives && response.adSheetCreatives.length) {
                if (this.isAwordsSearchCampaign) {
                  let newCreatives = response.adSheetCreatives[0];
                  if (response.adSheetCreatives[0].adGroups) {
                    newCreatives = response.adSheetCreatives.map(creative => ({
                      ...creative,
                      adGroups: creative.adGroups.filter(adGroup => adGroup.HOT_IsNotPublished !== false)
                    }));
                  }
                  this.$store.commit("set_publishedCreatives", newCreatives[0])

                  let publishedCreatives = response.adSheetCreatives[0];
                  if (response.adSheetCreatives[0].adGroups) {
                    publishedCreatives = response.adSheetCreatives.map(creative => ({
                      ...creative,
                      adGroups: creative.adGroups.filter(adGroup => adGroup.HOT_IsNotPublished !== true)
                    }));
                  }
                  this.$store.commit("set_publishedSheetCreatives", publishedCreatives[0])
                  this.$store.commit("set_totalSheetCreatives", response.adSheetCreatives[0])
                  if (this.totalSheetCreatives && this.totalSheetCreatives.adGroups) {
                    let localAdGroupNameToIdentifierMapper = {};
                    this.totalSheetCreatives.adGroups.forEach((e) => { if (e.valid == "true") localAdGroupNameToIdentifierMapper[e.adgroup] = e.Identifier });
                    this.$store.commit("setAdGroupMapper", localAdGroupNameToIdentifierMapper);
                  }
                } else {
                  this.$store.commit("set_publishedCreatives", response.adSheetCreatives[0]);
                }
              } else {
                this.$store.commit("setAdGroupMapper", {});
              }
              if (response && response.adSheetCreatives && response.adSheetCreatives.length) {
                this.set_adWordsAdSheetSettings(response.adSheetCreatives[0].adSheetSettings);
                EventBus.$emit("adsheetWidget-configSwitched");
              }
              this.isCreateSheetDataCallCompletedForAdwords = true;
            })
            .catch((exception) => {
              // We know something failed so need to know what failed and raise Error.
              this.$store.commit("set_showCampaignLauncherError", true);
              this.$store.commit(
                "set_campaignLauncherErrorMessage",
                `Unable to fetch Create sheet data.`
              );
              console.log("Unable to fetch Create sheet data.", exception)
            });
        }
      }
    },
    setCreativesInitialState () {
      let creativeJson = this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId] ? this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId].creativeJson : "";
      if (creativeJson) {
        this.set_publishedCreatives(creativeJson);
      } else {
        this.set_publishedCreatives({});
        this.set_createSheetCreatives({});
      }
      if (this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId] && this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId].editedCreativeJson) {
        this.$store.commit("set_publishedCreativesInitialState", this.publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId].editedCreativeJson)
      } else {
        this.$store.commit("set_publishedCreativesInitialState", {})
      }
    },
    setEditCreativesInitialState () {
      this.$store.commit("set_editSheetCreatives", '{"inStream":[],"imageBanner":[],"nativeImage":[],"nativeVideo":[],"nativeLogo":[],"customJson":[]}');
    },
    resetSheetData () {
      this.adSheetMode = 'Create';
      this.isEditMode = false;
      this.isPublishMode = false;
      this.isEditDataFetchComplete = false;
      this.getAdsheetData()
    },
    async processAdwordsPromotion () {
      let promoObj = this.promotionObject;
      let previewableSpec = this.getAllRows()
      promoObj.creativeJson["google"] = {
        handsonSpec: JSON.stringify(previewableSpec),
        googleSpec: JSON.stringify(this.creativesToPublish)
      }

      if (this.isLivePromotion) {
        let latestPromotionVersion = await APIService.getLatestPromotionVersion(promoObj.id);
        let originalCreativeJson = latestPromotionVersion.creativeJson;
        latestPromotionVersion.creativeJson = JSON.stringify({
          facebook: promoObj.creativeJson["facebook"],
          google: {
            handsonSpec: JSON.stringify(previewableSpec),
            googleSpec: JSON.stringify(this.creativesToPublish)
          }
        });

        // checking if any changes made
        if (!this.areJsonObjectsEqual(this.removeUnusedHandsonspec(latestPromotionVersion.creativeJson), this.removeUnusedHandsonspec(originalCreativeJson))) {
          if (latestPromotionVersion.status == promotionVersionEnums.Draft) {
            // CASE: When in edit flow, changes has been made to schedule/budget and new version is already created.
            this.stopNavigationToSummaryPage = false;
            await APIService.patchPromotionVersion(latestPromotionVersion.id, latestPromotionVersion);
          } else {
            // CASE: When changes has been made for the first time and new promotion is not created yet.
            this.stopNavigationToSummaryPage = true;
            this.showConfirmModal({
              content: `Changes detected, this will create a new version. Launch is disabled until you test and enable the promotion. Confirm to proceed.`,
              onOk: async () => {
                this.stopNavigationToSummaryPage = false;
                latestPromotionVersion.status = promotionVersionEnums.Draft;
                // setting these properties to null as they well be set in the post of prmoVersion
                latestPromotionVersion.createdAt = null;
                latestPromotionVersion.updatedAt = null;
                latestPromotionVersion.lastUpdatedByUserId = null;
                latestPromotionVersion.createdByUserId = null;
                await APIService.createPromotionVersion(promoObj.id, latestPromotionVersion);
                const replaceMacrosResponse = await APIService.replacePromotionMacros(
                  this.promotionId,
                  null,
                  null,
                  this.selectedPublisher
                );
                this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
                this.saveAndGenerateEntities();
                EventBus.$emit("PromotionLaunchWizard-enableLaunchButton");
              },
              onCancel: () => {
                this.fetchAlwaysCallback();
              }
            });
          }
        } else {
          this.stopNavigationToSummaryPage = false;
        }
      } else if (promoObj.status != promotionStatus.enabled) {
        this.stopNavigationToSummaryPage = false;
        await APIService.updatePromotionCreative(this.promotionId, {
          CreativeJson: promoObj.creativeJson
        });
      }
      if (!this.stopNavigationToSummaryPage) {
        const replaceMacrosResponse = await APIService.replacePromotionMacros(
          this.promotionId,
          null,
          null,
          this.selectedPublisher
        );
        this.replaceMacroAndCommitSpec(promoObj, replaceMacrosResponse);
      }
    },
    setAdSheetSettingsInHotSpec (hotRows) {
      if (this.placementCustomizationSettings) {
        hotRows["adSheetSettings"] = this.placementCustomizationSettings
      } else if (this.dpaCreativeToolSettings) {
        hotRows["adSheetSettings"] = this.dpaCreativeToolSettings
      } else if (this.languageCustomizationSettings) {
        hotRows["adSheetSettings"] = this.languageCustomizationSettings
        this.set_adSheetCustomizationSettings(this.languageCustomizationSettings)
      }
      hotRows.adSheetSettings = {
        ...(hotRows.adSheetSettings || {}),
        adLabelDimensions: this.adLabelDimensionSettings
      }
    },
    updateCreativeSetupData (creative) {
      let selectedHeadersObjects = fbEnums.placementCustomization.columns.filter(column => creative.adSheetSettings.selectedHeaderOptions.includes(column.id))
      creative.single.forEach(row => {
        if (row && row.creativeSetupData && row.creativeSetupData.hotTableData && row.creativeSetupData.type == 'Placement Customization') {
          if (selectedHeadersObjects.length == 0) {
            delete row.creativeSetupData;
          } else {
            var HotColumnNameIndexMapper = {}
            let oldCreativeSetup = JSON.parse(JSON.stringify(row.creativeSetupData.hotTableData))
            let columnsArray = oldCreativeSetup[0]
            HotColumnNameIndexMapper[columnsArray[0]] = 0
            selectedHeadersObjects.forEach(obj => {
              const index = columnsArray.findIndex(columnName => columnName.startsWith(obj.hotLabel));
              if (index !== -1) {
                HotColumnNameIndexMapper[columnsArray[index]] = index;
              }
              for (let i = 1; i <= obj.count; i++) {
                const index = columnsArray.findIndex(columnName => columnName.startsWith(obj.hotLabel) && columnName.endsWith(i));
                if (index !== -1) {
                  HotColumnNameIndexMapper[columnsArray[index]] = index;
                }
              }
            });
            let newColumns = [columnsArray[0]]; // Initialize with the first column
            selectedHeadersObjects.forEach(obj => {
              newColumns.push(obj.hotLabel); // Add the base hotLabel
              // Add optional suffixes based on count
              for (let i = 1; i < obj.count; i++) {
                newColumns.push(`${obj.hotLabel} - Optional ${i}`);
              }
            });
            var newValuesArray = []
            for (let i = 1; i < oldCreativeSetup.length; i++) {
              let currentValues = oldCreativeSetup[i]
              var filteredValues = []
              newColumns.forEach(column => {
                filteredValues.push(currentValues[HotColumnNameIndexMapper[column]])
              })
              newValuesArray.push(filteredValues)
            }
            row.creativeSetupData.hotTableData = [newColumns, ...newValuesArray]
          }
        }
      })
    },
    adsCountCreateTab (adFormat) {
      return h => {
        return h('div', [
          h('span', this.capitalize(adFormat) + ' '),
          h(Badge, {
            props: {
              text: `${this.$store.state.adsCountCreateTab[adFormat]}`,
              'class-name': 'badge-count',
              'show-zero': true,
              'overflow-count': '99999'
            }
          })
        ])
      }
    },
    adsCountEditTab (adFormat) {
      return h => {
        return h('div', [
          h('span', this.capitalize(adFormat) + ' '),
          h(Badge, {
            props: {
              text: `${this.$store.state.adsCountEditTab[adFormat]}`,
              'class-name': 'badge-count',
              'show-zero': true,
              'overflow-count': '99999'
            }
          })
        ])
      }
    },
    adsCountPublishTab (adFormat) {
      return h => {
        return h('div', [
          h('span', this.capitalize(adFormat) + ' '),
          h(Badge, {
            props: {
              text: `${this.$store.state.adsCountPublishTab[adFormat]}`,
              'class-name': 'badge-count',
              'show-zero': true,
              'overflow-count': '99999'
            }
          })
        ])
      }
    },
    showMolocoNextStep () {
      this.$store.commit("set_isCreationFlow", true);
      if (!this.autoNavigateToNextStep) {
        this.getPreviewableRows();
      }
      this.SaveCreative(true, this.autoNavigateToNextStep);
    },
    molocoSuccessCallback (response) {
      var data = response.data;
      if (data && data.campaignLauncherPublishDataId) {
        this.set_molocoCampaignLauncherPublishDataId(data.campaignLauncherPublishDataId);
        if (!this.submissionId) {
          this.$store.commit("SET_SHOW_PREVIEW", true);
          this.updateRouteToReview();
        } else {
          this.set_renderSubmissionSummary(true)
        }
      } else {
        // We know something failed so need to know what failed and raise Error.
        this.$store.commit("set_showCampaignLauncherError", true);
        this.$store.commit(
          "set_campaignLauncherErrorMessage",
          `Something went wrong while processing your request.`
        );
        this.$store.commit("set_isCreationFlow", false);
      }
    },
    getCampaignLauncherPublishDataId (skipSaveEntities = false, submissionId) {
      var requestData = {
        configId: this.selectedCampaignLauncherConfigId,
        mediaPlanId: this.mediaPlanId,
        submissionId: submissionId || this.submissionId || undefined,
        skipSaveEntities: skipSaveEntities
      };
      APIService.PostCampaignLauncherRequestWithAuth(
        requestData
      ).then(response => {
        this.molocoSuccessCallback(response)
      }).catch((response) => {
        this.fetchErrorCallback(response)
      }).finally(this.fetchAlwaysCallback);
    },
    getColHeadersFromAdSheetSettings (adSheetSettings) {
      if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType['Demand Gen']) {
        let demandGenColumnNames = {};
        let assetTypeRequirements = getAssetRequirements(this.selectedCampaignLauncherConfig.campaign.type)
        for (let adFormat in adSheetSettings) {
          if (assetTypeRequirements.hasOwnProperty(adFormat)) {
            for (let item in adSheetSettings[adFormat]) {
              if (adSheetSettings[adFormat].hasOwnProperty(item) && assetTypeRequirements[adFormat][item]) {
                if (item == 'card') {
                  for (let i = assetTypeRequirements[adFormat][item].min + 1; i <= adSheetSettings[adFormat][item]; i++) {
                    let colHeaderName1 = item + i + 'MarketingImage';
                    let colHeaderName2 = item + i + 'SquareMarketingImage';
                    let colHeaderName3 = item + i + 'PortraitMarketingImage';
                    let colHeaderName4 = item + i + 'Headline';
                    let colHeaderName5 = item + i + 'FinalURL';
                    let colHeaderName6 = item + i + 'CallToAction';
                    if (demandGenColumnNames[adFormat]) {
                      demandGenColumnNames[adFormat].push(colHeaderName1, colHeaderName2, colHeaderName3, colHeaderName6, colHeaderName4, colHeaderName5);
                    } else {
                      demandGenColumnNames[adFormat] = [colHeaderName1, colHeaderName2, colHeaderName3, colHeaderName6, colHeaderName4, colHeaderName5];
                    }
                  }
                } else {
                  for (let i = assetTypeRequirements[adFormat][item].min + 1; i <= adSheetSettings[adFormat][item]; i++) {
                    if (demandGenColumnNames[adFormat]) {
                      demandGenColumnNames[adFormat].push(item + i);
                    } else {
                      demandGenColumnNames[adFormat] = [item + i];
                    }
                  }
                }
              }
            }
          }
        }
        return demandGenColumnNames;
      } else {
        let colNames = [];
        let assetTypeRequirements = getAssetRequirements(this.selectedCampaignLauncherConfig.campaign.type)
        for (let item in adSheetSettings) {
          if (assetTypeRequirements[item]) {
            for (let i = assetTypeRequirements[item].min + 1; i <= adSheetSettings[item]; i++) {
              colNames.push(item + i);
            }
          }
        }
        return colNames;
      }
    },
    executeRenderLogic () {
      if (this.selectedFbObjective) {
        if (this.skipResetSelectedTab) {
          this.skipResetSelectedTab = false;
        } else {
          this.selectedTab = "Single";
        }
        if (this.selectedCampaignLauncherConfig.adset.isDynamicCreative) {
          this.$store.commit(
            "set_adFormats", this.getDynamicCreatives(this.selectedCampaignLauncherConfig)
          )
        } else {
          let colConfig = Object.keys(
            columnConfig(
              this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
            )
          );
          if (this.isPromotionMode) {
            this.$store.commit(
              "set_adFormats", colConfig.filter(function (value) {
                return (value !== 'singleCatalog' && value !== 'carouselCatalog' && value !== 'collectionCatalog')
              })
            );
          } else {
            this.$store.commit(
              "set_adFormats", colConfig
            );
          }
        }

        if (this.isDPAObjective(this.selectedCampaignLauncherConfig) && (this.publishedCreatives || {}).adSheetSettings) {
          this.dpaCreativeToolSettings = { Frame: false, "Catalogue Information": false };
          let dpaExtraHeaders = [];
          if (this.publishedCreatives.adSheetSettings["Frame"]) {
            this.dpaCreativeToolSettings["Frame"] = true;
            dpaExtraHeaders.push(...fbEnums.dpaCreativeEditColumns["Frame"])
          }
          if (this.publishedCreatives.adSheetSettings["Catalogue Information"]) {
            this.dpaCreativeToolSettings["Catalogue Information"] = true;
            dpaExtraHeaders.push(...fbEnums.dpaCreativeEditColumns["Catalogue Information"])
          }
          this.dpaCreativeToolHeaders = dpaExtraHeaders.length > 0 ? dpaExtraHeaders : null;
        }

        if (((this.publishedCreatives || {}).adSheetSettings || {}).adLabelDimensions) {
          this.adLabelDimensionSettings = this.publishedCreatives.adSheetSettings.adLabelDimensions;
        } else {
          this.adLabelDimensionSettings = [...(this.adLabelDimensionNomenclature || [])];
        }

        var adsCountCraeteTab = {};
        var adsCountEditTab = {};
        var adsCountPublishTab = {};
        this.adFormats.forEach(adFormat => { // single, carousel ...
          /**
           * colHeaders: {
           *  single: []
           *  carousel: []
           * }
           */
          adsCountCraeteTab[adFormat] = 0;
          adsCountEditTab[adFormat] = 0;
          adsCountPublishTab[adFormat] = 0;
          this.languageCustomizationConfigHeaders[adFormat] = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          )[adFormat]["languageCustomizationHeaders"];

          this.dynamicCreativeConfigHeaders[adFormat] = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          )[adFormat]["DynamicCreativeHeaders"];

          if (this.publishedCreatives.adSheetSettings && this.publishedCreatives.adSheetSettings["languageHeaders"]) {
            this.languageCustomizationHeaders = this.languageCustomizationHeaders || {};
            this.languageCustomizationHeaders[adFormat] = this.publishedCreatives.adSheetSettings["languageHeaders"];
            this.languageCustomizationSettings = this.publishedCreatives.adSheetSettings;
          }

          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat)
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat)
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat)
          const hotColumnConfig = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          );
          this.placementOptions[adFormat] = placementOptions(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          );
          this.placementCustomizationConfigHeaders[adFormat] = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          )[adFormat]["placementCustomizationHeaders"];

          if (this.placementCustomizationHeadersLocalVar[adFormat]) {
            this.placementCustomizationHeaders[adFormat] = this.placementCustomizationHeadersLocalVar[adFormat]
          } else {
            this.placementCustomizationHeaders[adFormat] = columnConfig(
              this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
            )[adFormat]["placementCustomizationHeaders"];
          }
          this.callToAction[adFormat] = hotColumnConfig[adFormat]["callToAction"];
          this.promoModeAutoFill[adFormat] = hotColumnConfig[adFormat]["promoModeAutoFill"] || {};
          this.showCreativeSetup[adFormat] = this.checkIfCreativeSetup(adFormat);
        })
        this.$store.commit("set_adsCountCreateTab", adsCountCraeteTab);
        this.$store.commit("set_adsCountEditTab", adsCountEditTab);
        this.$store.commit("set_adsCountPublishTab", adsCountPublishTab);
      } else if (this.selectedObjectiveIdForPublisher && this.selectedPublisher === this.publisher.LinkedIn) {
        this.selectedTab = "Single";
        this.$store.commit(
          "set_adFormats",
          Object.keys(
            columnConfig(
              this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
            )
          )
        );
        this.adFormats.forEach(adFormat => {
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat)
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat)
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat)
          const hotColumnConfig = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          );
          this.callToAction[adFormat] = hotColumnConfig[adFormat]["callToAction"];
        })
      } else if (this.selectedCampaignLauncherConfigId && this.selectedPublisher === this.publisher.DV360) {
        this.targetsMappedToConfig = this.getTargetsMappedToConfig();

        const hotColumnConfig = columnConfig(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
        );
        let adFormats = Object.keys(hotColumnConfig).filter(adFormat => this.showDV360AdFormatCheck(adFormat));
        this.$store.commit(
          "set_adFormats",
          adFormats
        );
        this.selectedTab = this.adFormats.length > 0 ? this.capitalize(this.adFormats[0]) : "";

        this.adFormats.forEach(adFormat => {
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat)
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat)
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat)

          this.callToAction[adFormat] = hotColumnConfig[adFormat]["callToAction"];
        })
      } else if (this.selectedCampaignLauncherConfigId && this.selectedPublisher === this.publisher.Twitter) {
        this.$store.commit(
          "set_adFormats",
          Object.keys(
            columnConfig(
              this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
            )
          )
        );
        this.selectedTab = this.capitalize(this.adFormats[0]);

        this.adFormats.forEach(adFormat => {
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat)
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat)
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat)
          const hotColumnConfig = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          );
          this.callToAction[adFormat] = hotColumnConfig[adFormat]["callToAction"];
        })
      } else if (this.selectedCampaignLauncherConfigId && this.selectedPublisher == this.publisher.Adwords) {
        var self = this;
        if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType.Search) {
          if (this.displayNetworkEnabled) {
            // if the displayNetwork is disabled only then show call only ads otherwise do not
            const index = this.adwordsAdFormats.indexOf('callAds');
            if (index != -1) {
              this.adwordsAdFormats.splice(index, 1);
            }
          } else {
            const index = this.adwordsAdFormats.indexOf('callAds');
            if (index == -1) {
              this.adwordsAdFormats.push('callAds');
            }
          }
        }
        if (!this.adwordsAdFormats.includes(this.selectedTab)) {
          this.selectedTab = this.adwordsAdFormats.length > 0 ? this.adwordsAdFormats[0] : "";
        }
        this.adwordsAdFormats.forEach(adFormat => {
          if (this.selectedCampaignLauncherConfig.campaign.type == adwordsEnums.campaignType["Demand Gen"]) {
            var customizedHeaders = this.getColHeadersFromAdSheetSettings(this.adwordsAdSheetSettingsInfo);
            this.customizedAdWordsColHeaders = customizedHeaders[adFormat] || []
          }
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat);
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat);
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat);
        });
        let campaignName = this.selectedCampaignLauncherConfig.campaign.name.userInput;
        let locationIdList = this.mappedLineItems.map(e => e.locations).flat().filter(e => e);
        const getTargetIds = this.isWizardFlow ? this.promotionObject.replacedMacroTargets.targetIds : this.mappedLineItems.map(e => e.targets).flat().filter(e => e);
        let getLocationListsFromMappedLineItems = this.mappedLineItems.map(e => e.locationsList).flat().filter(e => e);
        let localTargets = [];
        getTargetIds.forEach(eT => {
          localTargets.push(this.savedTargets.find(eS => (eS.Id == eT || eS.id == eT)));
        });
        let localLocations = [];
        let finalLocations = [];
        var isHyperLocal = this.selectedCampaignLauncherConfig.campaign.locationMode == adwordsEnums.locationMode.Hyperlocal
        if (!getLocationListsFromMappedLineItems.length) {
          locationIdList.forEach(function (e) {
            if (isHyperLocal) {
              localLocations.push(self.dbStores.find(store => store.storeCode == e));
            } else {
              localLocations.push(self.initialLocations.find(loc => loc.googleCode == e));
            }
          });
        } else {
          localLocations = getLocationListsFromMappedLineItems;
        }
        if (isHyperLocal) {
          finalLocations = localLocations.map(function (e) { return { name: e.name, googleCode: e.storeCode } });
        } else {
          finalLocations = localLocations.map(function (e) { return { name: e.completePath, googleCode: e.googleCode } });
        }
        let localCampaigns = {};
        let oldLocalCampaigns = {};
        let oneCampaignPerLocation = this.selectedCampaignLauncherConfig.campaign.locationMultiplier == 2;
        if (oneCampaignPerLocation) {
          if (finalLocations.length > 0) {
            localTargets.forEach(eT => {
              finalLocations.forEach(eL => {
                localCampaigns[`${campaignName}-${eT.Name}(${eT.Id})-${eL.name}`] = `T${eT.Id}-L${eL.googleCode}`;
                oldLocalCampaigns[`${campaignName}-${eT.Name}-${eL.name}`] = `T${eT.Id}-L${eL.googleCode}`;
              })
            });
          } else {
            localTargets.forEach(eT => {
              localCampaigns[`${campaignName}-${eT.Name}(${eT.Id})`] = `T${eT.Id}`;
              oldLocalCampaigns[`${campaignName}-${eT.Name}`] = `T${eT.Id}`;
            });
          }
        } else {
          let locationsCodes = locationIdList.join("-L");
          if (locationsCodes.length > 0) {
            localTargets.forEach(e => {
              localCampaigns[`${campaignName}-${e.name}(${e.id})`] = `T${e.id}-L${locationsCodes}`;
              oldLocalCampaigns[`${campaignName}-${e.name}`] = `T${e.id}-L${locationsCodes}`;
            });
          } else {
            localTargets.forEach(e => {
              localCampaigns[`${campaignName}-${e.name}(${e.id})`] = `T${e.id}`;
              oldLocalCampaigns[`${campaignName}-${e.name}`] = `T${e.id}`;
            });
          }
        }
        this.generateCampaigns = localCampaigns;
        this.generateOldCampaigns = oldLocalCampaigns;
        if (this.publishedCreatives && this.publishedCreatives.adGroups) {
          if (this.isAwordsSearchCampaign && !this.isPromotionMode) {
            let localAdGroupNameToIdentifierMapper = {};
            this.totalSheetCreatives.adGroups.forEach((e) => { if (e.valid == "true") localAdGroupNameToIdentifierMapper[e.adgroup] = e.Identifier });
            this.$store.commit("setAdGroupMapper", localAdGroupNameToIdentifierMapper);
          } else {
            let localAdGroupNameToIdentifierMapper = {};
            this.publishedCreatives.adGroups.forEach((e) => { localAdGroupNameToIdentifierMapper[e.adgroup] = e.Identifier });
            this.$store.commit("setAdGroupMapper", localAdGroupNameToIdentifierMapper);
          }
        }
      } else if (this.selectedCampaignLauncherConfigId && this.selectedPublisher === this.publisher.Moloco) {
        this.$store.commit(
          "set_adFormats",
          Object.keys(
            columnConfig(
              this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
            )
          )
        );
        this.selectedTab = this.capitalize(this.adFormats[0]);

        this.adFormats.forEach(adFormat => {
          [this.colHeaders[adFormat], this.displayColHeaders[adFormat]] = this.buildColHeaders(adFormat)
          this.requiredCols[adFormat] = this.buildRequiredCols(adFormat)
          this.creativeSpec[adFormat] = this.buildCreativeSpec(adFormat)
          this.creativeHeaders[adFormat] = this.buildCreativeHeaders(adFormat)
          const hotColumnConfig = columnConfig(
            this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
          );
          this.callToAction[adFormat] = hotColumnConfig[adFormat]["callToAction"];
        })
      }
    },
    getEditedPreviewableRows () {
      let newRows = {};
      if (this.selectedPublisher == this.publisher.Adwords) {
        let self = this;
        this.adwordsAdFormats.forEach(adFormat => {
          let rows = self.$refs[`${adFormat}HotEditTable`] && self.$refs[`${adFormat}HotEditTable`][0] ? self.$refs[`${adFormat}HotEditTable`][0].getPreviewableRows() : []
          if (adFormat == "responsiveAds" || adFormat == "textAds" || adFormat == "callAds" || adFormat == "responsiveDisplayAds" || adFormat == "appAds" || adFormat == "appEngagementAds" || adFormat == "discoveryCarouselAds" || adFormat == "discoveryImageAds" || adFormat == "discoveryVideoAds") {
            if (!newRows["Ads"]) {
              newRows["Ads"] = [];
            }
            newRows["Ads"] = newRows["Ads"].concat(rows);
          } else {
            newRows[adFormat] = rows;
          }
        });
      }
      this.$store.commit("set_editedCreativesToPublish", newRows)
    },
    addCampaignColumn (headers) {
      headers.splice(1, 0, "Campaign Name");
      return headers;
    },
    processAdwordsColHeaders (colHeaders, customizedAdWordsColHeaders) {
      let fields = multipleColumnsHeaderName;
      for (const header of fields) {
        let headerFromCustomizedColHeaders = customizedAdWordsColHeaders.filter(obj => obj.includes(header));
        let lastIndex = -1;
        for (let i = colHeaders.length - 1; i >= 0; i--) {
          if (colHeaders[i].includes(header)) {
            lastIndex = i;
            break;
          }
        }
        if (headerFromCustomizedColHeaders && headerFromCustomizedColHeaders.length) {
          if (lastIndex !== -1) {
            let before = colHeaders.slice(0, lastIndex + 1);
            let after = colHeaders.slice(lastIndex + 1);
            colHeaders = before.concat(headerFromCustomizedColHeaders, after);
          } else {
            colHeaders = colHeaders.concat(headerFromCustomizedColHeaders);
          }
        }
      }
      return colHeaders;
    }
  }
};
</script>

<style scoped>
.save-time {
  position: absolute;
  right: 30px;
  padding-top: 3px;
}
.edit-toggle {
  position: absolute;
  right: 45%;
  z-index: 99;
}
.ad-sheet-settings {
  position: absolute;
  right: 255px;
  top: 0rem;
  z-index: 2;
}
.copy-headers {
  position: absolute;
  right: 385px;
  top: 0rem;
  z-index: 2;
}
.hot-spinner {
  font-size: 3rem;
  margin-top: 2rem;
  line-height: 5rem;
  text-align: center;
}
.ad-sheet-container {
  padding: 10px;
  height: 100%;
}
.tabs {
  height: 100%;
}
.tab-pane {
  display: initial;
}
.capitalize:first-letter {
  text-transform: capitalize;
}
.adsheet-alert-enter-active {
  transition: all 0.35s ease-out;
}
.adsheet-alert-enter {
  opacity: 0;
}
.macros-block {
  border: 1px solid #ccc;
  padding: 1rem;
  width: 40%;
  position: absolute;
  bottom: 3rem;
  margin-bottom: 1.5rem;
}
.macro-block-description {
  font-size: 1.6rem;
}
.macro-value {
  font-size: 1.3rem;
  cursor: pointer;
  line-height: 2.2rem;
}
.macro-block-list {
  max-height: 10rem;
  overflow-y: auto;
}
.fas.fa-copy {
  margin-right: 1rem;
}
.ivu-tabs-tab .ivu-tabs-tab-disabled {
  display: none;
}
</style>

<style>
.dx-tab-style .ivu-tabs-nav-container {
  max-width: 400px !important;
}
.badge-count {
  background: #B1B2B2 !important;
  font-family: Arimo, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.tab-pane.capitalize.ivu-tabs-tabpane .tooltip.fade.top.in {
  position: fixed !important;
}
.edit-toggle .ivu-radio-group-button .ivu-radio-wrapper:after {
  width: 0px !important;
}
.edit-toggle .create-mode.ivu-radio-wrapper-checked {
  box-shadow: 0px 0 0 0 #51b848 !important;
}
.edit-toggle .ivu-radio-wrapper:hover {
  color: #51b848 !important;
}
.edit-toggle .ivu-radio-focus {
  box-shadow: none;
}
.edit-toggle .ivu-radio-wrapper-checked {
  border-color: #51b848 !important;
  color: #51b848 !important;
  box-shadow: -1px 0 0 0 #51b848 !important;
}
.linkedIn-adsheet-tab .ivu-tabs-tab-disabled {
  display: none;
}
.cl-adpreview-page .ivu-page-simple-pager, .cl-adpreview-page .ivu-page-simple-pager input {
  margin: 0px !important;
}
.cl-adpreview-page input {
  width: 60px !important;
}
</style>
<style src="../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
